import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'components';
import { useFetchAccountingFilters, usePerms, type TFilterAtoms } from 'hooks';
import * as atoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';
import AccountingReportFilter from './AccountingReportFilter';
import AccountingReportFilterDateRange from './AccountingReportFilterDateRange';
import AccountingReportFilterNormalize from './AccountingReportFilterNormalize';
import AccountingReportFiltersApplyButton from './AccountingReportFiltersApplyButton';
import AccountingReportFiltersClearButton from './AccountingReportFiltersClearButton';
import AccountingReportFilterTesters from './AccountingReportFilterTesters';

const AccountingReportFilters = () => {
  const { t } = useTranslation();
  useFetchAccountingFilters();
  const perms = usePerms();

  const filtersData = useMemo(() => {
    const data = [
      {
        title: t('accounting.operators'),
        atoms: atoms.operatorsAtoms,
      },
      {
        title: t('accounting.games'),
        atoms: atoms.gamesAtoms,
      },
      {
        title: t('accounting.currencies'),
        atoms: atoms.currenciesAtoms,
      },
      {
        title: t('accounting.countries'),
        atoms: atoms.countriesAtoms,
      },
    ];
    if (perms.clients) {
      data.unshift({
        title: t('accounting.clients'),
        atoms: atoms.clientsAtoms,
      });
    }
    if (perms.clusters) {
      data.unshift({
        title: t('accounting.clusters'),
        atoms: atoms.clustersAtoms,
      });
    }
    return data;
  }, [perms]);

  const maxWidthMedia = `@media (max-width: ${(filtersData.length + 1) * 265}px)`;
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: `repeat(${filtersData.length + 1}, 1fr)`,
        justifyContent: 'center',
        [maxWidthMedia]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
      }}
    >
      {filtersData.map((item) => (
        <AccountingReportFilter key={item.title} atoms={item.atoms as TFilterAtoms} title={item.title} />
      ))}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 220,
        }}
      >
        <Typography sx={{ borderBottom: '1px solid', paddingBottom: 1, borderColor: 'primary.main' }}>
          <b>{t('accounting.fromTo')}</b>
        </Typography>
        <AccountingReportFilterDateRange />
        <AccountingReportFilterNormalize />
        <AccountingReportFilterTesters />
        <AccountingReportFiltersClearButton />
        <AccountingReportFiltersApplyButton filtersData={filtersData} />
      </Box>
    </Box>
  );
};

export default AccountingReportFilters;
