import { Fragment, useCallback, useEffect, useRef, useState, type MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useAtom, useAtomValue } from 'jotai';
import { Autocomplete, Box, Divider, IconButton, MenuItem, TextField, Typography } from 'components';
import { type IAccountingFilterGroup, type TFilterAtoms } from 'hooks/useAccountingFilters';
import AccountingReportFiltersGroupEditor from './AccountingReportFiltersGroupEditor';

interface IProps {
  title: string;
  atoms: TFilterAtoms;
}

export const AccountingReportFiltersGroup = ({ title, atoms }: IProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useAtom(atoms.selected);
  const data = useAtomValue(atoms.data);
  const groups = useAtomValue(atoms.groups);
  const [selectedGroup, setSelectedGroup] = useState<IAccountingFilterGroup | null>(null);
  const [groupToEdit, setGroupToEdit] = useState<IAccountingFilterGroup | null>(null);
  const lastSelectedGroupValuesRef = useRef<string[] | null>(null);

  useEffect(() => {
    if (selectedGroup && selected !== lastSelectedGroupValuesRef.current) {
      setSelectedGroup(null);
    }
  }, [selected, lastSelectedGroupValuesRef]);

  useEffect(() => {
    setSelectedGroup(null);
  }, [groups]);

  const handleSelectGroup = useCallback(
    (group: IAccountingFilterGroup) => {
      setSelectedGroup(group);
      const values = group?.values.filter((el) => data.some((d) => d.id === el)) || [];
      lastSelectedGroupValuesRef.current = values;
      setSelected(values);
    },
    [data],
  );

  const handleAddGroup = useCallback(() => {
    const group = {
      id: crypto.randomUUID(),
      name: '',
      values: [...selected],
    };
    setGroupToEdit(group);
  }, [selected]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        size="small"
        sx={{ flexGrow: 1 }}
        options={groups}
        getOptionLabel={(option) => option.name}
        renderOption={(
          props: {
            onMouseMove?: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined;
            onTouchStart?: ((event: React.TouchEvent<HTMLLIElement>) => void) | undefined;
          },
          option,
          state,
        ) => {
          return (
            <Fragment key={option.id}>
              {state.index === 0 && (
                <>
                  <MenuItem
                    data-option-index={-1}
                    onMouseMove={props.onMouseMove as MouseEventHandler<HTMLLIElement>}
                    onTouchStart={props.onTouchStart}
                    onClick={handleAddGroup}
                  >
                    {t('accounting.addNewGroup')}
                  </MenuItem>
                  <Divider />
                </>
              )}
              <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography title={option.name} noWrap>
                  {option.name}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setGroupToEdit(option);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </li>
            </Fragment>
          );
        }}
        onChange={(_, group) => handleSelectGroup(group!)}
        value={selectedGroup}
        title={selectedGroup?.name}
        noOptionsText={<MenuItem onClick={handleAddGroup}>{t('accounting.addNewGroup')}</MenuItem>}
        renderInput={(params) => (
          <TextField {...params} size="small" variant="outlined" label={t('accounting.selectByGroup')} />
        )}
      />
      {groupToEdit && (
        <AccountingReportFiltersGroupEditor
          title={title}
          group={groupToEdit}
          close={() => setGroupToEdit(null)}
          atoms={atoms}
        />
      )}
    </Box>
  );
};

export default AccountingReportFiltersGroup;
