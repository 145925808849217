import { useCallback, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { type GridSortModel } from '@mui/x-data-grid';
import { useAtomValue, useStore } from 'jotai';
import { dataGridRowsPerPageAtom } from 'components';
import { EFilterTesters, ENormalizeGroups } from 'hooks/useAccountingFilters';
import * as filterAtoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';
import { useSetLoading } from 'hooks/useLoading';
import { usePerms } from 'hooks/usePerms';
import { parseBigNumber, parseCountryCode, showError } from 'utils';
import { appliedFiltersAtom, type IAppliedFilters } from './accountingDataAtoms';

const GET_DATA = gql`
  query ReportTransactions(
    $filter: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $normalized: Boolean!
    $groupBy: String
    $withClusters: Boolean!
    $withClients: Boolean!
  ) {
    reportTransactions(
      filter: $filter
      skip: $skip
      take: $take
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalized: $normalized
      groupBy: $groupBy
    ) {
      items {
        transactionsCount
        ggr
        country
        currency
        slotLabel
        operatorLabel
        clusterLabel @include(if: $withClusters)
        clientLabel @include(if: $withClients)
        creditTotal
        debitTotal
        refundTotal
        totalBuyBet
        totalBuyCount
      }
      summary @include(if: $normalized) {
        ggrSummary
        debitTotalSummary
        creditTotalSummary
        refundTotalSummary
        totalBuyBetSummary
        totalBuyCountSummary
      }
      totalCount
      skip
      take
      normalized
      groupBy
    }
  }
`;

interface IRawRecord {
  ggr?: number;
  clusterLabel?: string;
  creditTotal?: number;
  currency: string;
  country?: string;
  debitTotal?: number;
  operatorLabel: string;
  clientLabel?: string;
  refundTotal?: number;
  slotLabel: string;
  transactionsCount: number;
  totalBuyBet: number;
  totalBuyCount: number;
}

interface ISummary {
  ggr: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  totalBuyBet: string;
  totalBuyCount: string;
}

interface IRawData {
  reportTransactions: {
    items: IRawRecord[];
    skip: number;
    take: number;
    totalCount: number;
    normalized: boolean;
    summary?: {
      ggrSummary: number;
      debitTotalSummary: number;
      creditTotalSummary: number;
      refundTotalSummary: number;
      totalBuyBetSummary: number;
      totalBuyCountSummary: number;
    };
  };
}

interface IRowData {
  id: string;
  clusterLabel?: string;
  clientLabel?: string;
  operatorLabel: string;
  slotLabel: string;
  currency: string;
  country?: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  transactionsCount: string;
  totalBuyBet: string;
  totalBuyCount: string;
  ggr: string;
}

export const playersMap = {
  [EFilterTesters.ALL]: undefined,
  [EFilterTesters.TESTERS_ONLY]: true,
  [EFilterTesters.REAL_ONLY]: false,
};

export const parseAccountingFilters = (appliedFilters: IAppliedFilters) => {
  const filters: Record<string, object | boolean | void> = {
    operatorId: { in: appliedFilters.operators },
    country: { in: appliedFilters.countries },
    currency: { in: appliedFilters.currencies },
    slotId: { in: appliedFilters.games },
    createdAt: { gte: appliedFilters.dateFrom, lte: appliedFilters.dateTo },
    tester: playersMap[appliedFilters.players],
  };
  if (appliedFilters.clusters.length > 0) {
    filters.clusterId = { in: appliedFilters.clusters };
  }
  if (appliedFilters.clients.length > 0) {
    filters.clientId = { in: appliedFilters.clients };
  }
  return filters;
};

const parseRawData = (data: IRawRecord[], normalizeCurrency = ''): IRowData[] => {
  return data.map((item) => {
    return {
      id: crypto.randomUUID(),
      clusterLabel: item.clusterLabel,
      clientLabel: item.clientLabel,
      operatorLabel: item.operatorLabel,
      slotLabel: item.slotLabel,
      currency: item.currency,
      country: item.country && parseCountryCode(item.country),
      transactionsCount: parseBigNumber(item.transactionsCount, 0),
      totalBuyCount: parseBigNumber(item.totalBuyCount, 0),
      totalBuyBet: parseBigNumber(item.totalBuyBet, 2, normalizeCurrency),
      debitTotal: parseBigNumber(item.debitTotal, 2, normalizeCurrency),
      creditTotal: parseBigNumber(item.creditTotal, 2, normalizeCurrency),
      ggr: parseBigNumber(item.ggr, 2, normalizeCurrency),
      refundTotal: parseBigNumber(item.refundTotal, 2, normalizeCurrency),
    };
  });
};

export const useAccountingData = () => {
  const store = useStore();
  const { startLoading, endLoading } = useSetLoading();
  const [getData] = useLazyQuery<IRawData>(GET_DATA);
  const appliedFilters = useAtomValue(appliedFiltersAtom);
  const rowsPerPage = useAtomValue(dataGridRowsPerPageAtom);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([]);
  const [data, setData] = useState<IRowData[]>([]);
  const [summary, setSummary] = useState<ISummary | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState(-1);
  const normalizeEnabled = useAtomValue(filterAtoms.normalizeEnabledAtom);
  const normalizeGroupBy = useAtomValue(filterAtoms.normalizeGroupByAtom);
  const perms = usePerms();

  const setSortAndClearPage = useCallback((...params: Parameters<typeof setSort>) => {
    setPage(0);
    setSort(...params);
  }, []);
  useEffect(() => store.sub(appliedFiltersAtom, () => setPage(0)), []);
  useEffect(() => {
    if (appliedFilters) {
      startLoading('fetchAccountingData');
      getData({
        fetchPolicy: 'network-only',
        variables: {
          filter: parseAccountingFilters(appliedFilters),
          skip: page * rowsPerPage,
          take: rowsPerPage,
          orderBy: sort[0]?.field,
          orderDirection: sort[0]?.sort,
          normalized: normalizeEnabled,
          groupBy: normalizeGroupBy === ENormalizeGroups.NONE ? undefined : normalizeGroupBy,
          withClusters: perms.clusters,
          withClients: perms.clients,
        },
      })
        .then((response) => {
          if (response.data?.reportTransactions) {
            const { summary, totalCount, items, normalized } = response.data.reportTransactions;
            const normalizeCurrency = normalized ? 'EUR' : '';
            const mappedData: IRowData[] = parseRawData(items, normalizeCurrency);
            setTotalDataCount(totalCount);
            setData(mappedData);
            setSummary(
              summary && {
                ggr: parseBigNumber(summary.ggrSummary, 2, normalizeCurrency),
                debitTotal: parseBigNumber(summary.debitTotalSummary, 2, normalizeCurrency),
                creditTotal: parseBigNumber(summary.creditTotalSummary, 2, normalizeCurrency),
                refundTotal: parseBigNumber(summary.refundTotalSummary, 2, normalizeCurrency),
                totalBuyBet: parseBigNumber(summary.totalBuyBetSummary, 2, normalizeCurrency),
                totalBuyCount: parseBigNumber(summary.totalBuyCountSummary, 0),
              },
            );
          } else if (response.error) {
            showError(response.error);
          }
          endLoading('fetchAccountingData');
        })
        .catch((err) => {
          showError(err);
          endLoading('fetchAccountingData');
        });
    }
  }, [appliedFilters, sort, page, rowsPerPage]);

  return { data: data || [], totalDataCount, page, setPage, sort, setSort: setSortAndClearPage, summary };
};
