import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { parseBigNumber, parseCountryCode } from 'utils';

interface IRawRecord {
  clusterLabel?: string;
  clientLabel?: string;
  creditAmount: number;
  currency: string;
  debitAmount: number;
  entityId: string;
  entityType: string;
  operatorLabel: string;
  playerExternalId: string;
  playerId: string;
  playerInternalId: string;
  slotLabel: string;
  tester: boolean;
  createdAt: string;
  country: string;
}

export interface IPlayerBrowserDetailsRawData {
  bets: { items: IRawRecord[]; skip: number; take: number; totalCount: number };
}

export const GET_PLAYER = gql`
  query GetPlayer(
    $where: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $withClusters: Boolean!
    $withClients: Boolean!
  ) {
    bets(filter: $where, take: $take, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      totalCount
      skip
      take
      items {
        slotLabel
        playerId
        playerInternalId
        playerExternalId
        debitAmount
        creditAmount
        operatorLabel
        clusterLabel @include(if: $withClusters)
        clientLabel @include(if: $withClients)
        entityId
        entityType
        currency
        createdAt
        country
      }
    }
  }
`;

export const parsePlayerBrowserDetailsData = (rawData: IPlayerBrowserDetailsRawData, lang: string) => {
  return {
    totalCount: rawData.bets.totalCount,
    data: rawData.bets.items.map((item) => {
      return {
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        cluster: item.clusterLabel,
        game: item.slotLabel,
        operator: item.operatorLabel,
        client: item.clientLabel,
        country: parseCountryCode(item.country, lang),
        //
        id: item.entityId,
        currency: item.currency,
        bet: parseBigNumber(item.debitAmount),
        win: parseBigNumber(item.creditAmount),
        date: dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss z'),
        roundType: item.entityType === 'BONUS' ? t('playerBrowser.bonusRound') : t('playerBrowser.betRound'),
      };
    }),
  };
};
