export const currencyGroups = [
  { name: 'Asia-Anakatech', id: 'Asia-Anakatech', values: ['BND', 'INR', 'LAK', 'SGD', 'THB', 'LTL', 'VND', 'JPY'] },
  {
    name: 'Asia-BetConstruct',
    id: 'Asia-BetConstruct',
    values: ['KHR', 'IDR', 'INR', 'MYR', 'MMK', 'MOP', 'PHP', 'SGD', 'THB', 'VND', 'CNY', 'HKD'],
  },
  {
    name: 'Asia-Hub88',
    id: 'Asia-Hub88',
    values: [
      'KHR',
      'IDR',
      'INR',
      'MYR',
      'SGD',
      'THB',
      'VND',
      'CNY',
      'HKD',
      'TWD',
      'JPY',
      'BDT',
      'KRW',
      'NPR',
      'PKR',
      'LKR',
      'TRY',
    ],
  },
];
