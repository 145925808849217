import { useTranslation } from 'react-i18next';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from 'components';

interface IProps {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

export const SearchInput = ({ value, onValueChange, disabled }: IProps) => {
  const { t } = useTranslation();

  return (
    <OutlinedInput
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      disabled={disabled}
      size="small"
      placeholder={t('base.placeholderSearch')}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      }
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => onValueChange('')} sx={{ marginRight: -1 }}>
              <ClearIcon fontSize="small" sx={{ cursor: 'pointer' }} />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export default SearchInput;
