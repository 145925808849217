import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { type IButtonInputsFormAppliedFilter, type TButtonInputsFormInitialState } from 'components';

export const useButtonInputsFormQueryParams = (search: string) => {
  const navigate = useNavigate();
  const setQueryParams = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = filters.map((filter) => ({ key: filter.key, state: filter.state }));
    try {
      const json = JSON.stringify(mappedFilters);
      const url = new URL(window.location.href);
      url.searchParams.set('filters', json);
      navigate(url.pathname + url.search, { replace: true });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const initialState: TButtonInputsFormInitialState = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const filtersJson = queryParams.get('filters');
    if (!filtersJson) {
      return {};
    }
    try {
      const data = JSON.parse(filtersJson) as IButtonInputsFormAppliedFilter[];
      return data.reduce((acc, filter) => {
        acc[filter.key] = filter.state;
        return acc;
      }, {} as TButtonInputsFormInitialState);
    } catch (err) {
      console.error(err);
    }
    return {};
  }, [search]);

  return { setQueryParams, initialState };
};
