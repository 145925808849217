import type { GridColDef } from '@mui/x-data-grid';
import type { Perms } from 'auth';
import type { TFunction } from 'i18next';

export const generateColumns = (t: TFunction, perms: Perms) =>
  [
    { editable: false, field: 'playerId', headerName: t('playerBrowser.playerId') },
    { editable: false, field: 'playerExternalId', headerName: t('playerBrowser.playerExternalId') },
    { editable: false, field: 'playerInternalId', headerName: t('playerBrowser.playerInternalId') },
    perms.clusters && { editable: false, field: 'clusterLabel', headerName: t('playerBrowser.cluster') },
    perms.clients && { editable: false, field: 'clientLabel', headerName: t('playerBrowser.client') },
    { editable: false, field: 'operatorLabel', headerName: t('playerBrowser.operator') },
    { editable: false, field: 'country', headerName: t('playerBrowser.country') },
    { editable: false, field: 'currency', headerName: t('playerBrowser.currency') },
    { editable: false, field: 'totalSpinCount', headerName: t('playerBrowser.betCount') },
    { editable: false, field: 'totalBuyCount', headerName: t('playerBrowser.buyFeatureCount') },
    { editable: false, field: 'debitAmount', headerName: t('playerBrowser.totalBet') },
    { editable: false, field: 'creditAmount', headerName: t('playerBrowser.totalWin') },
    { editable: false, field: 'tester', headerName: t('playerBrowser.isTester') },
    { editable: false, field: 'firstTransactionDate', headerName: t('playerBrowser.firstActivity') },
    { editable: false, field: 'lastTransactionDate', headerName: t('playerBrowser.lastActivity') },
  ].filter(Boolean) as GridColDef[];
