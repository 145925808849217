import { useEffect, useRef, useState, type ReactNode } from 'react';
import Grow from '@mui/material/Grow';
import Paper, { type PaperProps } from '@mui/material/Paper';
import Popper, { type PopperProps } from '@mui/material/Popper';

export interface IPopperMenuProps extends PopperProps {
  paperProps?: PaperProps;
  dontCloseOnOutsideClick?: boolean;
  onClose: () => void;
}

export const PopperMenu = ({
  onClose,
  open,
  paperProps,
  children,
  dontCloseOnOutsideClick,
  anchorEl,
  ...rest
}: IPopperMenuProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [growOpen, setGrowOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setGrowOpen(true);
      if (dontCloseOnOutsideClick) {
        return;
      }
      const callback = (evt: MouseEvent) => {
        let insideRoot = false;
        let target = evt.target as HTMLElement | null;
        while (target) {
          if (target === rootRef.current) {
            insideRoot = true;
            break;
          }
          target = target.parentElement;
        }
        if (insideRoot === false) {
          setGrowOpen(false);
        }
      };
      setTimeout(() => {
        window.addEventListener('click', callback);
        window.addEventListener('contextmenu', callback);
      });
      return () => {
        window.removeEventListener('contextmenu', callback);
        window.removeEventListener('click', callback);
      };
    }
  }, [open, dontCloseOnOutsideClick]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      {...rest}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        ...rest.sx,
      }}
    >
      <Grow in={growOpen} onExited={onClose}>
        <Paper elevation={6} {...paperProps} ref={rootRef}>
          {children as ReactNode}
        </Paper>
      </Grow>
    </Popper>
  );
};

export default PopperMenu;
