import { ToastContainer } from 'react-toastify';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { ApolloProvider } from '@apollo/client';
import { initSupertokens } from 'auth';
import { Loading, Theme } from 'components';
import { Router } from 'routing';
import { client } from './apollo';
import { initTranslations } from './translation';

initTranslations();
initSupertokens();

const App = () => {
  return (
    <SuperTokensWrapper>
      <ApolloProvider client={client}>
        <Theme>
          <Loading />
          <Router />
          <ToastContainer />
        </Theme>
      </ApolloProvider>
    </SuperTokensWrapper>
  );
};

export default App;
