import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { atom, useStore } from 'jotai';
import * as Yup from 'yup';
import { Box, Button, FormControl, HeaderText, InputLabel, MenuItem, Select, TextField } from 'components';
import {
  EUserRole,
  EUserStatus,
  useFetchAccountingFilters,
  useLoading,
  useUser,
  useUserManagement,
  type IUserInput,
} from 'hooks';
import * as accountingFiltersAtoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';
import { isSpecificError } from 'utils';
import AccountingReportFilter from 'views/AccountingReport/AccountingReportFilters/AccountingReportFilter';
import NotFound from 'views/NotFound';

export const TeamMemberUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const newUser = id === 'add';
  const store = useStore();
  const currentUser = useUser();
  useFetchAccountingFilters();
  const { rawData, updateUser, addUser, error } = useUserManagement(id, newUser);
  const { loading } = useLoading();

  const selectedAtoms = useMemo(
    () => ({
      clusters: atom<string[]>([]),
      clients: atom<string[]>([]),
      operators: atom<string[]>([]),
    }),
    [],
  );

  const handleAccept = useCallback(
    async (data: Record<string, string>) => {
      const userInput: IUserInput = {
        role: data.role as EUserRole,
        status: rawData?.user.status as EUserStatus,
        clientsIds: store.get(selectedAtoms.clients),
        clustersIds: store.get(selectedAtoms.clusters),
        operatorsIds: store.get(selectedAtoms.operators),
      };

      if (newUser) {
        userInput.email = data.email;
        addUser(userInput);
      } else {
        updateUser(userInput);
      }
    },
    [rawData],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('login.notValidEmail')).required('Required'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: { email: '', role: EUserRole.ACCOUNT_MANAGER, status: EUserStatus.PENDING },
    onSubmit: handleAccept,
  });

  useEffect(() => {
    if (rawData) {
      store.set(selectedAtoms.clusters, rawData.user.clustersIds);
      store.set(selectedAtoms.clients, rawData.user.clientsIds);
      store.set(selectedAtoms.operators, rawData.user.operatorsIds);
      formik.setValues({ email: rawData.user.email, role: rawData.user.role, status: rawData.user.status });
    }
  }, [rawData]);

  if (
    isSpecificError(error, 'Cannot return null for non-nullable field Query.user.') ||
    rawData?.user.id === currentUser.id
  ) {
    return <NotFound overrideTitle={t('team.editUser')} overrideSubtitle={t('team.userNotFound', { id })} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 4 }}>
      <HeaderText>{t(newUser ? 'team.addUser' : 'team.editUser')}</HeaderText>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <TextField
              size="small"
              name="email"
              label={t('login.email')}
              required
              disabled={!newUser || loading}
              sx={{ flexGrow: 1 }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.submitCount > 0 && formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.submitCount > 0 && formik.touched.email && formik.errors.email}
            />
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel size="small">{t('team.role')}</InputLabel>
              <Select
                size="small"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                disabled={loading}
                label={t('team.role')}
              >
                <MenuItem value={EUserRole.ACCOUNT_MANAGER}>{t('team.accountManager')}</MenuItem>
                <MenuItem value={EUserRole.ADMIN}>{t('team.admin')}</MenuItem>
                <MenuItem value={EUserRole.AREA_ADMIN}>{t('team.areaAdmin')}</MenuItem>
                <MenuItem value={EUserRole.SUPPORT}>{t('team.support')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel size="small">{t('team.status')}</InputLabel>
              <Select
                disabled={formik.values.status === EUserStatus.PENDING || loading}
                size="small"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                label={t('team.status')}
              >
                <MenuItem disabled value={EUserStatus.PENDING}>
                  {t('team.pending')}
                </MenuItem>
                <MenuItem value={EUserStatus.ACTIVE}>{t('team.active')}</MenuItem>
                <MenuItem value={EUserStatus.DEACTIVE}>{t('team.deactive')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {[
              {
                key: 'clusters',
                atoms: { ...accountingFiltersAtoms.clustersAtoms, selected: selectedAtoms.clusters },
              },
              {
                key: 'clients',
                atoms: { ...accountingFiltersAtoms.clientsAtoms, selected: selectedAtoms.clients },
              },
              {
                key: 'operators',
                atoms: { ...accountingFiltersAtoms.operatorsAtoms, selected: selectedAtoms.operators },
              },
            ].map(({ key, atoms }) => (
              <AccountingReportFilter key={key} title={t('accounting.' + key)} atoms={atoms} />
            ))}
          </Box>

          <Button disabled={loading} type="submit">
            {t('base.accept')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default TeamMemberUser;
