import { useCallback, useEffect, useRef, useState, type PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { type SxProps } from '@mui/material';
import { AppBar, Box, IconButton, Paper, Typography } from 'components';

interface IProps extends PropsWithChildren {
  title: string;
  onClose?: () => void;
  startPosition?: { x: number; y: number };
  startSize?: { x: number; y: number };
  startOpened?: boolean;
  sx?: SxProps;
  elevation?: number;
}

const EMPTY_IMAGE = new Image(1, 1);
EMPTY_IMAGE.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const resizeElements = [
  {
    id: 'resizeX',
    sx: { position: 'absolute', height: '100%', width: 6, right: -3, top: 0, cursor: 'ew-resize' },
    resizeX: true,
    resizeY: false,
  },
  {
    id: 'resizeY',
    sx: { position: 'absolute', width: '100%', height: 6, bottom: -3, left: 0, cursor: 'ns-resize' },
    resizeX: false,
    resizeY: true,
  },
  {
    id: 'resizeXY',
    sx: { position: 'absolute', width: 10, height: 10, bottom: -5, right: -5, cursor: 'nwse-resize' },
    resizeX: true,
    resizeY: true,
  },
] as const;

export const SubWindow = ({
  title,
  children,
  onClose,
  startPosition,
  startSize,
  startOpened,
  sx,
  elevation,
}: IProps) => {
  const [opened, setOpened] = useState(startOpened);
  const rootRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(startSize || { x: 500, y: 500 });
  const [position, setPosition] = useState(startPosition || { x: window.innerWidth - 20 - size.x, y: 20 });
  const startDragPositionRef = useRef({ x: 0, y: 0 });
  // safari returns wrong position onDragEnd
  const dragPositionRef = useRef({ x: 0, y: 0 });
  const [isDrawing, setIsDrawing] = useState(false);

  const onDragStart = useCallback((evt: React.DragEvent) => {
    evt.stopPropagation();
    evt.dataTransfer.setDragImage(EMPTY_IMAGE, 0, 0);
    startDragPositionRef.current = { x: evt.clientX, y: evt.clientY };
    setIsDrawing(true);
  }, []);

  const onDragResize = useCallback(
    (evt: React.DragEvent, currentSize: { x: number; y: number }, resizeX: boolean, resizeY: boolean) => {
      if (!rootRef.current || (evt.clientX === 0 && evt.clientY === 0)) {
        return;
      }
      dragPositionRef.current.x = evt.clientX;
      dragPositionRef.current.y = evt.clientY;
      const diffX = resizeX ? evt.clientX - startDragPositionRef.current.x : 0;
      const diffY = resizeY ? evt.clientY - startDragPositionRef.current.y : 0;
      rootRef.current.style.width = Math.max(400, currentSize.x + diffX) + 'px';
      rootRef.current.style.height = Math.max(400, currentSize.y + diffY) + 'px';
    },
    [size],
  );

  const onDragEndResize = useCallback((evt: React.DragEvent, resizeX: boolean, resizeY: boolean) => {
    if (!rootRef.current || (evt.clientX === 0 && evt.clientY === 0)) {
      return;
    }
    const diffX = resizeX ? dragPositionRef.current.x - startDragPositionRef.current.x : 0;
    const diffY = resizeY ? dragPositionRef.current.y - startDragPositionRef.current.y : 0;
    setSize((currentSize) => ({ x: Math.max(250, currentSize.x + diffX), y: Math.max(250, currentSize.y + diffY) }));
    setIsDrawing(false);
  }, []);

  useEffect(() => {
    let debounceId: NodeJS.Timeout;
    const onResize = () => {
      clearTimeout(debounceId);
      debounceId = setTimeout(() => {
        setPosition((pos) => {
          const width = rootRef.current?.clientWidth || 50;
          const height = rootRef.current?.clientHeight || 50;
          const x = Math.max(-width + 50, Math.min(window.innerWidth - 50, pos.x));
          const y = Math.max(-height + 50, Math.min(window.innerHeight - 50, pos.y));
          return { x, y };
        });
      }, 100);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return createPortal(
    <Box
      ref={rootRef}
      sx={[
        {
          position: 'fixed',
          pointerEvents: opened ? 'all' : 'none',
          left: position.x,
          top: position.y,
          width: size.x,
          height: size.y,
          zIndex: (theme) => theme.zIndex.drawer + 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AppBar elevation={elevation || 3} sx={{ position: 'relative', width: '100%', pointerEvents: 'all' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pr: 1,
            cursor: 'grab',
            userSelect: 'none',
          }}
          draggable
          onDrag={(evt) => {
            if (!rootRef.current || (evt.clientX === 0 && evt.clientY === 0)) {
              return;
            }
            dragPositionRef.current.x = evt.clientX;
            dragPositionRef.current.y = evt.clientY;
            const diffX = evt.clientX - startDragPositionRef.current.x;
            const diffY = evt.clientY - startDragPositionRef.current.y;
            rootRef.current.style.transform = `translate(${diffX}px, ${diffY}px)`;
          }}
          onDragStart={onDragStart}
          onDragEnd={(evt) => {
            if (!rootRef.current || (evt.clientX === 0 && evt.clientY === 0)) {
              return;
            }
            const diffX = dragPositionRef.current.x - startDragPositionRef.current.x;
            const diffY = dragPositionRef.current.y - startDragPositionRef.current.y;
            setPosition((pos) => {
              const width = rootRef.current?.clientWidth || 50;

              const x = Math.max(-width + 50, Math.min(window.innerWidth - 50, pos.x + diffX));
              const y = Math.max(10, Math.min(window.innerHeight - 50, pos.y + diffY));
              return { x, y };
            });
            rootRef.current.style.transform = `none`;
            setIsDrawing(false);
          }}
        >
          <Typography sx={{ flexGrow: 1 }}>{title}</Typography>
          <IconButton color="inherit" size="small" onClick={() => setOpened((b) => !b)}>
            <RemoveIcon fontSize="small" />
          </IconButton>
          <IconButton color="inherit" size="small" onClick={onClose}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      </AppBar>
      <Paper
        square
        elevation={elevation || 3}
        sx={{
          pointerEvents: opened ? 'all' : 'none',
          position: 'relative',
          display: opened ? 'block' : 'none',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: isDrawing ? 'none' : 'all',
          }}
        >
          {children}
        </Box>
        {resizeElements.map((el) => (
          <Box
            key={el.id}
            sx={el.sx}
            draggable
            onDrag={(evt) => onDragResize(evt, size, el.resizeX, el.resizeY)}
            onDragStart={onDragStart}
            onDragEnd={(evt) => onDragEndResize(evt, el.resizeX, el.resizeY)}
          />
        ))}
      </Paper>
    </Box>,

    document.body,
  );
};

export default SubWindow;
