import { Box, Button } from 'components';

interface IProps {
  disabled: boolean;
  onActivate: () => void;
  onDeactive: () => void;
  onDelete: () => void;
}

export const TeamMembersBulkActions = ({ disabled, onActivate, onDeactive, onDelete }: IProps) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
      {(
        [
          {
            key: 'activate',
            color: 'success',
            text: 'Activate Selected',
            onClick: onActivate,
          },
          {
            key: 'deactive',
            color: 'info',
            text: 'Deactive Selected',
            onClick: onDeactive,
          },
          {
            key: 'delete',
            color: 'error',
            text: 'Delete Selected',
            onClick: onDelete,
          },
        ] as const
      ).map(({ key, color, text, onClick }) => (
        <Button
          key={key}
          onClick={onClick}
          variant="outlined"
          disabled={disabled}
          color={color}
          size="small"
          sx={{ textTransform: 'none' }}
        >
          {text}
        </Button>
      ))}
    </Box>
  );
};

export default TeamMembersBulkActions;
