import i18next from 'i18next';

const intls: Record<string, Intl.DisplayNames> = {};

export const parseCountryCode = (countryCode: string, language = i18next.language) => {
  try {
    if (!intls[language]) {
      intls[language] = new Intl.DisplayNames(language, { type: 'region' });
    }
    return intls[language].of(countryCode) || countryCode;
  } catch (e) {
    return countryCode;
  }
};
