import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Box, CircularProgress, IconButton, PopperMenu, Tooltip, Typography } from 'components';
import { useReportExport } from 'hooks';
import { downloadFile } from 'utils';

export const ReportExport = () => {
  const { generatingReport, generatedReportUrl, clear } = useReportExport();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!generatingReport && generatedReportUrl) {
      setOpen(true);
    }
    window.onbeforeunload = generatingReport ? () => true : null;
  }, [generatingReport, generatedReportUrl]);

  if (!generatingReport && !generatedReportUrl) {
    return null;
  }

  return (
    <>
      <Tooltip title={generatingReport && t('reportExport.generating')}>
        <IconButton color="inherit" ref={buttonRef} onClick={() => !generatingReport && setOpen((bool) => !bool)}>
          {generatingReport ? <CircularProgress color="inherit" size={20} /> : <DownloadingIcon />}
        </IconButton>
      </Tooltip>
      <PopperMenu
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
        paperProps={{ sx: { p: 2 } }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{t('reportExport.reportGenerated')}</Typography>
          <IconButton
            size="small"
            onClick={() => {
              downloadFile(generatedReportUrl);
            }}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton size="small" onClick={clear}>
            <ClearIcon />
          </IconButton>
        </Box>
      </PopperMenu>
    </>
  );
};

export default ReportExport;
