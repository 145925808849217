import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { type Dayjs } from 'dayjs';
import { FormControl, InputLabel, MenuItem } from 'components';
import { usePerms } from 'hooks';
import {
  ENormalizeGroups,
  ENormalizePossibleCurrencies,
  useAccountingFiltersDate,
  useAccountingFiltersNormalize,
} from 'hooks/useAccountingFilters';

export const AccountingReportFilterNormalize = () => {
  const { t } = useTranslation();
  const date = useAccountingFiltersDate();
  const normalize = useAccountingFiltersNormalize();
  const currencyDate = useMemo(() => dayjs(normalize.currencyDate), [normalize.currencyDate]);
  const perms = usePerms();

  const maxDate = useMemo(() => dayjs().subtract(1, 'day'), []);
  const minDate = useMemo(() => dayjs().subtract(1, 'year'), []);

  const handleCurrencyDateChange = useCallback((date: Dayjs | null) => {
    date && normalize.setCurrencyDate(date.format());
  }, []);

  useEffect(() => {
    normalize.setCurrencyDate(dayjs(normalize.currencyDate).tz(date.zone).format());
  }, [date.zone]);

  return (
    <>
      <FormControl>
        <InputLabel size="small">{t('accounting.Normalize')}</InputLabel>
        <Select
          value={normalize.enabled ? normalize.currency : 'none'}
          size="small"
          label={t('accounting.Normalize')}
          onChange={(evt) => {
            const { value } = evt.target;
            if (value === 'none') {
              normalize.setEnabled(false);
              normalize.setGroupBy(ENormalizeGroups.NONE);
            } else {
              normalize.setEnabled(true);
              normalize.setCurrency(value as ENormalizePossibleCurrencies);
            }
          }}
        >
          <MenuItem value={'none'}>{t('accounting.OriginalCurrencies')}</MenuItem>
          {Object.values(ENormalizePossibleCurrencies).map((currency) => (
            <MenuItem key={currency} value={currency}>
              {`${t('accounting.NormalizeTo')} ${currency}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={!normalize.enabled}
          maxDate={maxDate}
          minDate={minDate}
          label={t('accounting.CurrencyDate')}
          slotProps={{ textField: { size: 'small' } }}
          value={currencyDate}
          onChange={handleCurrencyDateChange}
          timezone={date.zone}
        />
      </LocalizationProvider>

      <FormControl>
        <InputLabel size="small">{t('accounting.GroupBy')}</InputLabel>
        <Select
          size="small"
          value={normalize.groupBy}
          disabled={!normalize.enabled}
          label={t('accounting.GroupBy')}
          onChange={(evt) => normalize.setGroupBy(evt.target.value as ENormalizeGroups)}
        >
          <MenuItem value={ENormalizeGroups.NONE}>{t('accounting.DontGroup')}</MenuItem>
          <MenuItem value={ENormalizeGroups.OPERATORS}>{t('accounting.GroupByOperator')}</MenuItem>
          <MenuItem value={ENormalizeGroups.GAMES}>{t('accounting.GroupByGame')}</MenuItem>
          <MenuItem value={ENormalizeGroups.COUNTRIES}>{t('accounting.GroupByCountry')}</MenuItem>
          <MenuItem value={ENormalizeGroups.CURRENCIES}>{t('accounting.GroupByCurrency')}</MenuItem>
          {perms.clients && <MenuItem value={ENormalizeGroups.CLIENTS}>{t('accounting.GroupByClient')}</MenuItem>}
          {perms.clusters && <MenuItem value={ENormalizeGroups.CLUSTERS}>{t('accounting.GroupByCluster')}</MenuItem>}
        </Select>
      </FormControl>
    </>
  );
};

export default AccountingReportFilterNormalize;
