import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { dateFromAtom, dateToAtom, timeZoneAtom } from './accountingFiltersAtoms';

export const useAccountingFiltersDate = () => {
  const [from, setFrom] = useAtom(dateFromAtom);
  const [to, setTo] = useAtom(dateToAtom);
  const [zone, setZone] = useAtom(timeZoneAtom);

  useEffect(() => {
    if (!from) {
      setFrom(dayjs().startOf('day').format());
    }
    if (!to) {
      setTo(dayjs().endOf('day').format());
    }
  }, []);

  return {
    from,
    setFrom,
    to,
    setTo,
    zone,
    setZone,
  };
};

export default useAccountingFiltersDate;
