import type { TFunction } from 'i18next';

export const generateColumns = (t: TFunction) => [
  { editable: false, field: 'id', headerName: t('playerBrowser.betId') },
  { editable: false, field: 'date', headerName: t('playerBrowser.date') },
  { editable: false, field: 'game', headerName: t('playerBrowser.gameName') },
  { editable: false, field: 'bet', headerName: t('playerBrowser.bet') },
  { editable: false, field: 'win', headerName: t('playerBrowser.win') },
  { editable: false, field: 'currency', headerName: t('playerBrowser.currency') },
  { editable: false, field: 'roundType', headerName: t('playerBrowser.roundType') },
];
