import { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery, type DocumentNode, type OperationVariables } from '@apollo/client';
import { type GridSortModel } from '@mui/x-data-grid';
import { atom, useAtom, useAtomValue, type PrimitiveAtom } from 'jotai';
import { dataGridRowsPerPageAtom } from 'components';
import { useSetLoading } from 'hooks/useLoading';
import { showError } from 'utils';

interface IProps {
  GQL: DocumentNode;
  loadingKey: string;
  fetchVariables: OperationVariables;
  keepData?: boolean;
  shouldShowError?: (err: Error) => boolean;
}

interface IAtoms<RawData = unknown> {
  page: PrimitiveAtom<number>;
  sort: PrimitiveAtom<GridSortModel>;
  rawData: PrimitiveAtom<RawData | null>;
  whereFilters: PrimitiveAtom<Record<string, unknown> | null>;
}

const makeAtoms = () =>
  ({
    page: atom(0),
    sort: atom([] as GridSortModel),
    rawData: atom(null),
    whereFilters: atom(null),
  }) as IAtoms;

const keepDataAtoms: Record<string, IAtoms> = {};

export const useButtonInputsFormWithDataGridAndDefaultQuery = <RawData = unknown>({
  GQL,
  loadingKey,
  fetchVariables = {},
  keepData,
  shouldShowError,
}: IProps) => {
  const { startLoading, endLoading } = useSetLoading();
  const rowsPerPage = useAtomValue(dataGridRowsPerPageAtom);
  const [fetchData, { error }] = useLazyQuery<RawData>(GQL);
  const atoms = useMemo(() => {
    if (keepData) {
      if (!keepDataAtoms[loadingKey]) {
        keepDataAtoms[loadingKey] = makeAtoms();
      }
      return keepDataAtoms[loadingKey] as IAtoms<RawData>;
    }
    return makeAtoms() as IAtoms<RawData>;
  }, []);

  const [whereFilters, setWhereFilters] = useAtom(atoms.whereFilters);
  const [rawData, setRawData] = useAtom(atoms.rawData);
  const [sort, setSort] = useAtom(atoms.sort);
  const [page, setPage] = useAtom(atoms.page);

  const setSortAndClearPage = useCallback((...params: Parameters<typeof setSort>) => {
    setPage(0);
    setSort(...params);
  }, []);

  const setWhereFiltersAndClearPage = useCallback((...params: Parameters<typeof setWhereFilters>) => {
    setPage(0);
    setWhereFilters(...params);
  }, []);

  useEffect(() => {
    if (whereFilters) {
      startLoading(loadingKey);
      fetchData({
        variables: {
          where: whereFilters,
          skip: page * rowsPerPage,
          take: rowsPerPage,
          orderBy: sort[0]?.field,
          orderDirection: sort[0]?.sort,
          ...fetchVariables,
        },
      })
        .then((response) => {
          endLoading(loadingKey);
          if (response.data) {
            setRawData(response.data);
          } else if (response.error) {
            if (shouldShowError && !shouldShowError(response.error)) {
              return;
            }
            showError(response.error);
          }
        })
        .catch((err) => {
          endLoading(loadingKey);
          if (shouldShowError && !shouldShowError(err)) {
            return;
          }
          showError(err);
        });
    }
  }, [whereFilters, page, rowsPerPage, sort]);

  return {
    setWhereFilters: setWhereFiltersAndClearPage,
    setSort: setSortAndClearPage,
    setPage,
    sort,
    page,
    rawData,
    error,
  };
};
