import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { LogoCompany } from 'assets';
import {
  AppBar,
  Box,
  IconButton,
  LanguageSelector,
  MenuItem,
  ModeToggle,
  PopperMenu,
  ReportExport,
  TimezoneSelector,
  Toolbar,
} from 'components';
import { useAuth, useMenu, useUserPreferences } from 'hooks';

export const Header = () => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const { setLeftMenuOpened } = useMenu();
  const {
    userPreferences: { timezone },
    setUserPreference,
  } = useUserPreferences();

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
      <Toolbar>
        <IconButton color="inherit" onClick={() => setLeftMenuOpened((b) => !b)}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          <LogoCompany lightColor="#ffffff" darkColor="#ffffff" />
        </Box>
        <Box sx={{ width: 220 }}>
          <TimezoneSelector
            minimalistic
            value={timezone}
            onChange={(timezone) => setUserPreference('timezone', timezone)}
          />
        </Box>
        <ReportExport />
        <ModeToggle />
        <LanguageSelector variant="icon" />
        <IconButton ref={anchorEl} color="inherit" onClick={() => setMenuOpened(true)}>
          <AccountCircle />
        </IconButton>
        <PopperMenu
          anchorEl={anchorEl.current}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
          placement="bottom-end"
          open={menuOpened}
          onClose={() => setMenuOpened(false)}
        >
          <MenuItem onClick={signOut}>{t('login.signOut')}</MenuItem>
        </PopperMenu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
