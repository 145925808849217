import { type TypographyProps } from '@mui/material/Typography';
import { Box, Typography } from 'components';

interface IProps extends TypographyProps {
  ignoreDrawer?: boolean;
}

export const HeaderText = ({ ignoreDrawer, ...rest }: IProps) => {
  if (ignoreDrawer) {
    return (
      <Box sx={{ mb: 4, mt: 4 }}>
        <Box sx={{ width: '100%', position: 'absolute', left: 0 }}>
          <Typography variant="h5" align="center" {...rest} />
        </Box>
      </Box>
    );
  }

  return <Typography variant="h5" align="center" {...rest} />;
};

export default HeaderText;
