import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { styled, type CSSObject, type Theme } from '@mui/material/styles';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from 'components';
import { useMenu, usePerms } from 'hooks';
import { generateMenuData } from './generateMenuData';

export const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const LeftMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { leftMenuOpened } = useMenu();
  const perms = usePerms();
  const menuData = useMemo(() => generateMenuData(perms), [perms]);

  return (
    <StyledDrawer variant="permanent" open={leftMenuOpened}>
      <Toolbar />
      <List>
        {menuData.map((menu) => (
          <Fragment key={menu.key}>
            <ListItem disablePadding sx={{ pl: 2 }} disabled={menu.disabled}>
              <ListItemIcon sx={{ minWidth: 32 }}>{menu.icon}</ListItemIcon>
              <ListItemText primary={t(menu.key)} />
            </ListItem>

            <List sx={{ p: 0, ml: 3, pl: 3, borderLeft: '1px solid', borderColor: 'divider' }}>
              {menu.groups.map((group) => (
                <Fragment key={group.key}>
                  <ListItem disablePadding dense disabled={group.disabled}>
                    <ListItemText primary={t(group.key)} />
                  </ListItem>
                  {group.routes.map((route) => (
                    <ListItem key={route.key} disablePadding dense>
                      <ListItemButton
                        sx={{ p: 0, pl: 2 }}
                        onClick={() => navigate(route.route, { unstable_viewTransition: true })}
                        selected={pathname.includes(route.route)}
                        disabled={route.disabled}
                      >
                        <ListItemText secondary={t(route.key)} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Fragment>
              ))}

              {menu.routes.map((route) => (
                <ListItem key={route.key} disablePadding dense>
                  <ListItemButton
                    sx={{ p: 0, pl: 2 }}
                    onClick={() => navigate(route.route, { unstable_viewTransition: true })}
                    selected={pathname === route.route}
                    disabled={route.disabled}
                  >
                    <ListItemText secondary={t(route.key)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Fragment>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default LeftMenu;
