import { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, MenuItem, PopperMenu } from 'components';

export interface ILogicOperator<Operator> {
  key: Operator;
  label: string;
  sign: string;
}

interface IProps<Operator> {
  operators: ILogicOperator<Operator>[];
  value: string;
  onChange: (value: Operator) => void;
}

export const LogicOperatorButton = <Operator extends string>({ operators, value, onChange }: IProps<Operator>) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        sx={{
          minWidth: 20,
          fontSize: '1rem',
          height: 20,
          padding: 0,
          marginLeft: 0.5,
          marginRight: 0.5,
          color: 'text.primary',
        }}
        variant="outlined"
        ref={buttonRef}
        component="div"
        color="primary"
        onClick={(evt) => {
          evt.stopPropagation();
          setOpen(true);
        }}
      >
        <Box component="span" sx={{ paddingLeft: 0.75 }}>
          {operators.find((operator) => operator.key === value)?.sign}
        </Box>
        <ArrowDropDownIcon fontSize="small" />
      </Button>
      <PopperMenu anchorEl={buttonRef.current} open={open} placement="bottom-start" onClose={() => setOpen(false)}>
        {operators.map((operator) => (
          <MenuItem
            key={operator.key}
            value={operator.key}
            onClick={(evt) => {
              evt.stopPropagation();
              setOpen(false);
              if (value !== operator.key) {
                onChange(operator.key);
              }
            }}
          >
            {`${operator.sign} (${operator.label})`}
          </MenuItem>
        ))}
      </PopperMenu>
    </>
  );
};

export default LogicOperatorButton;
