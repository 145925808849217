import { useCallback, useEffect, type MutableRefObject } from 'react';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';
import { getCanvasFont, getComputedStyle, measureText } from 'utils';
import { useUserPreferences } from './useUserPreferences';

const LOCAL_STORAGE_KEY_PREFIX = 'x-data-grid-columns-width--';

export const useSaveDataGridColumnsWidth = (
  localStorageKey: string,
  apiRef: MutableRefObject<GridApiCommunity>,
  additionalColumnElements?: Record<string, string>,
) => {
  const { userPreferences, setUserPreference } = useUserPreferences();

  const adjustColumnsWidth = useCallback(() => {
    const columns = apiRef.current.getVisibleColumns();
    const rowIds = apiRef.current.getAllRowIds();
    const widths: Record<string, number> = {};
    let cell: HTMLElement | null = null;

    for (const c of columns) {
      if (c.field === '__check__') {
        continue;
      }
      cell = apiRef.current.getColumnHeaderElement(c.field);
      for (const r of rowIds) {
        cell = apiRef.current.getCellElement(r, c.field);
        if (cell) {
          break;
        }
      }
      if (cell) {
        break;
      }
    }
    if (!cell) {
      return;
    }

    const paddingLeft = Number(getComputedStyle(cell, 'padding-left').replace('px', '')) || 0;
    const paddingRight = Number(getComputedStyle(cell, 'padding-right').replace('px', '')) || 0;
    const font = getCanvasFont(cell || document.body);

    columns.forEach((column) => {
      if (column.field === '__check__') {
        apiRef.current.setColumnWidth(column.field, 20);
        return;
      }
      widths[column.field] = measureText(column.headerName || '', font).width;
      if (additionalColumnElements?.[column.field]) {
        widths[column.field] = Math.max(
          widths[column.field],
          measureText(additionalColumnElements[column.field], font).width,
        );
      }
      rowIds.forEach((rowId) => {
        const cellValue = apiRef.current.getCellValue(rowId, column.field);
        widths[column.field] = Math.max(widths[column.field], measureText(cellValue, font).width);
      });
      apiRef.current.setColumnWidth(column.field, widths[column.field] + paddingLeft + paddingRight + 10);
    });
  }, []);

  useEffect(() => {
    if (userPreferences.autoAdjustColumnsWidth) {
      return apiRef.current.subscribeEvent('rowsSet', () => {
        // I didin't find better way to fire adjustColumnsWidth after all rows are rendered
        // event renderedRowsIntervalChange doesn't work for this purpose
        setTimeout(adjustColumnsWidth, 40);
      });
    }
  }, [userPreferences.autoAdjustColumnsWidth]);

  useEffect(() => {
    const saveColumnsWidth = () => {
      const columns = apiRef.current.getAllColumns();
      const dataToSave = columns.reduce(
        (acc, column) => {
          acc[column.field] = Number(column.width);
          return acc;
        },
        {} as Record<string, number>,
      );
      localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey, JSON.stringify(dataToSave));
    };

    try {
      const columnsWidthJson = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey);
      if (columnsWidthJson) {
        const parsedColumnsWidth = JSON.parse(columnsWidthJson);
        const columns = apiRef.current.getAllColumns();
        columns.forEach((column) => {
          const savedWidth = parsedColumnsWidth?.[column.field];
          if (savedWidth && typeof savedWidth === 'number') {
            apiRef.current.setColumnWidth(column.field, savedWidth);
          }
        });
      } else if (userPreferences.autoAdjustColumnsWidth) {
        adjustColumnsWidth();
      }
    } catch (err) {
      console.error(err);
    }

    const unsubColumnResize = apiRef.current.subscribeEvent('columnResizeStop', () =>
      setUserPreference('autoAdjustColumnsWidth', false),
    );
    const unsubColumnWidthChange = apiRef.current.subscribeEvent('columnWidthChange', saveColumnsWidth);

    return () => {
      unsubColumnResize();
      unsubColumnWidthChange();
    };
  }, []);

  return { adjustColumnsWidth };
};
