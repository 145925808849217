import { Box, LinearProgress } from '@mui/material';
import { useLoading } from 'hooks';

export const Loading = () => {
  const { loading } = useLoading();

  return (
    loading && (
      <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: (theme) => theme.zIndex.drawer + 4 }}>
        <LinearProgress />
      </Box>
    )
  );
};

export default Loading;
