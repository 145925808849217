import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from 'dayjs';
import {
  Box,
  DateTimeRangePicker,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TimezoneSelector,
  Typography,
} from 'components';
import { useAccountingFiltersDate } from 'hooks/useAccountingFilters';

export const AccountingReportFilterDateRange = () => {
  const { t } = useTranslation();
  const date = useAccountingFiltersDate();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const dateFromText = useMemo(() => dayjs(date.from).format('DD/MM/YYYY'), [date.from]);
  const timeFromText = useMemo(() => dayjs(date.from).format('HH:mm:ss'), [date.from]);
  const dateToText = useMemo(() => dayjs(date.to).format('DD/MM/YYYY'), [date.to]);
  const timeToText = useMemo(() => dayjs(date.to).format('HH:mm:ss'), [date.to]);

  const onAcceptDateRange = useCallback(
    (from: string, to: string) => {
      date.setFrom(from);
      date.setTo(to);
    },
    [date],
  );

  const handleTimezone = useCallback(
    (timezone: string) => {
      date.setZone(timezone);
      date.setFrom(dayjs(date.from).tz(timezone).format());
      date.setTo(dayjs(date.to).tz(timezone).format());
    },
    [date.zone, date.from, date.to],
  );

  return (
    <>
      <DateTimeRangePicker
        open={openDatePicker}
        close={() => setOpenDatePicker(false)}
        onAccept={onAcceptDateRange}
        initFrom={date.from}
        initTo={date.to}
        zone={date.zone}
      />
      <TimezoneSelector value={date.zone} onChange={handleTimezone} />
      <FormControl>
        <InputLabel>{t('accounting.DateRange')}</InputLabel>
        <OutlinedInput
          readOnly
          label={t('accounting.DateRange')}
          onClick={() => setOpenDatePicker(true)}
          sx={{ cursor: 'pointer' }}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ cursor: 'pointer', color: 'text.primary' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarTodayIcon fontSize="small" />
                  <Typography sx={{ marginLeft: 0.5 }} variant="body2">
                    {dateFromText}
                  </Typography>
                  <AccessTimeIcon fontSize="small" sx={{ marginLeft: 1 }} />
                  <Typography sx={{ marginLeft: '2px' }} variant="body2">
                    {timeFromText}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarTodayIcon fontSize="small" />
                  <Typography sx={{ marginLeft: 0.5 }} variant="body2">
                    {dateToText}
                  </Typography>
                  <AccessTimeIcon fontSize="small" sx={{ marginLeft: 1 }} />
                  <Typography sx={{ marginLeft: '2px' }} variant="body2">
                    {timeToText}
                  </Typography>
                </Box>
              </Box>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};

export default AccountingReportFilterDateRange;
