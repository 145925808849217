import { useCallback, useEffect, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAtom, type PrimitiveAtom } from 'jotai';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemText, PopperMenu } from 'components';

export interface ISelectButtonInputOption {
  name: string;
  id: string;
}

export interface ISelectButtonInputState {
  value: string;
}

interface IProps {
  atom: PrimitiveAtom<ISelectButtonInputState>;
  label: string;
  options: ISelectButtonInputOption[];
  disabled?: boolean;
  onToggle?: (open: boolean) => void;
}

export const SelectButtonInput = ({ atom, options, label, onToggle, disabled }: IProps) => {
  const [state, setState] = useAtom(atom);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    onToggle?.(open);
  }, [open, onToggle]);

  const toggleValue = useCallback((id: string) => {
    setState((prevState) => ({ value: prevState.value === id ? '' : id }));
  }, []);

  return (
    <>
      <Button
        disabled={disabled}
        ref={buttonRef}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.value ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.value && ` = ${options.find((el) => el.id === state.value)?.name}`}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>
      <PopperMenu open={open} anchorEl={buttonRef.current} onClose={() => setOpen(false)} placement="bottom-start">
        <List
          sx={{
            overflow: 'auto',
            maxHeight: 240,
            minHeight: 60,
          }}
        >
          {options.map((option) => (
            <ListItem key={option.id} disablePadding>
              <ListItemButton dense onClick={() => toggleValue(option.id)}>
                <Checkbox checked={state.value === option.id} edge="start" size="small" />
                <ListItemText primary={option.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </PopperMenu>
    </>
  );
};

export default SelectButtonInput;
