import { useCallback, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useAtom, type SetStateAction } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const SET_USER_PREFERENCES = gql`
  mutation SetUserPreferences($updateUsersInput: UpdateUserPreferencesInput!) {
    updateUserPreferences(updateUsersInput: $updateUsersInput) {
      darkMode
      timezone
    }
  }
`;

export interface IUserPreferences {
  darkMode: boolean;
  timezone: string;
  autoAdjustColumnsWidth: boolean;
}

export const userPreferencesAtom = atomWithStorage<IUserPreferences>(
  'userPreferences',
  {
    darkMode: false,
    timezone: '',
    autoAdjustColumnsWidth: true,
  },
  undefined,
  { getOnInit: true },
);

export const validateUserPreferences = (preferences: IUserPreferences) => {
  return {
    darkMode: preferences.darkMode ?? false,
    timezone: preferences.timezone ?? dayjs.tz.guess(),
    autoAdjustColumnsWidth: preferences.autoAdjustColumnsWidth ?? true,
  };
};

export const useUserPreferences = () => {
  const [userPreferences, setUserPreferences] = useAtom(userPreferencesAtom);
  const [saveUserPreferences] = useMutation(SET_USER_PREFERENCES);
  const setUserPreferencesAndSave = useCallback((input: SetStateAction<IUserPreferences>) => {
    setUserPreferences((prevValue) => {
      const value = validateUserPreferences(typeof input === 'function' ? input(prevValue) : input);
      saveUserPreferences({ variables: { updateUsersInput: value } });
      return value;
    });
  }, []);

  useEffect(() => {
    dayjs.tz.setDefault(userPreferences.timezone);
  }, [userPreferences.timezone]);

  const setUserPreference = useCallback(
    <T extends keyof IUserPreferences>(
      key: T,
      value: IUserPreferences[T] | ((prev: IUserPreferences[T]) => IUserPreferences[T]),
    ) => {
      setUserPreferencesAndSave((prev) => {
        const newValue = typeof value === 'function' ? value(prev[key]) : value;
        if (prev[key] === newValue) {
          return prev;
        }
        return { ...prev, [key]: newValue };
      });
    },
    [],
  );

  return { userPreferences, setUserPreference };
};
