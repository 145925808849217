import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { fetchUser } from 'auth';
import { showToast } from 'utils';

export const PrivateRoute = () => {
  const { t } = useTranslation();

  return (
    <SessionAuth
      onSessionExpired={() => {
        fetchUser();
        showToast({ title: t('login.sessionExpired'), severity: 'info' });
      }}
    >
      <Outlet />
    </SessionAuth>
  );
};

export default PrivateRoute;
