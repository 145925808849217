import { toast, type ToastContentProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { AlertProps } from '@mui/material/Alert';
import { Alert, AlertTitle } from 'components';

interface IProps extends AlertProps {
  text?: string;
  title?: string;
}

const Toast = ({
  text,
  title,
  data: _data,
  closeToast: _closeToast,
  toastProps: _toastProps,
  ...rest
}: IProps & ToastContentProps) => {
  return (
    <Alert elevation={4} {...rest}>
      {title && <AlertTitle sx={{ mb: 0 }}>{title}</AlertTitle>}
      {text}
    </Alert>
  );
};

export const showToast = (props: IProps) => {
  toast(<Toast {...(props as IProps & ToastContentProps)} />, {
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
    style: { backgroundColor: 'transparent', boxShadow: 'none', padding: 0 },
    pauseOnHover: true,
  });
};
