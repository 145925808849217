import { forwardRef, type ForwardedRef } from 'react';
import MuiButton, { type ButtonProps } from '@mui/material/Button';

// eslint-disable-next-line react/display-name
export const Button = forwardRef(({ children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
  <MuiButton data-testid="button" variant="contained" {...props} ref={ref}>
    {children}
  </MuiButton>
));

export default Button;
