import { useEffect, useRef } from 'react';
import { GridFooterContainer, useGridApiContext } from '@mui/x-data-grid';
import { Box, DataGridPagination } from 'components';

interface IProps {
  summary?: Record<string, string>;
  totalDataCount: number;
  page: number;
  setPage: (page: number) => void;
}

const heights: Record<string, number> = {
  compact: 70,
  standard: 80,
  comfortable: 90,
};

export const AccountingReportFooter = ({ summary, totalDataCount, page, setPage }: IProps) => {
  const apiRef = useGridApiContext();
  const summaryRef = useRef<HTMLDivElement>(null);
  const columns = apiRef.current.getVisibleColumns();

  useEffect(() => {
    return apiRef.current.subscribeEvent('scrollPositionChange', (evt) => {
      if (summaryRef.current) {
        summaryRef.current.scrollLeft = evt.left;
      }
    });
  });

  return (
    <GridFooterContainer
      sx={{
        minHeight: heights[apiRef.current.state.density] || 52,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      }}
    >
      {summary && (
        <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden' }} ref={summaryRef}>
          {columns.map((col) => {
            return (
              <Box
                role="gridcell"
                key={col.field}
                className={
                  'MuiDataGrid-cell' +
                  (col.field === 'ggr' ? (summary[col.field]?.[0] === '-' ? ' ggr-negative' : ' ggr-positive') : '')
                }
                sx={{
                  fontWeight: 'bold',
                }}
                ref={(ref: HTMLDivElement) => ref && ref.style.setProperty('--width', `${col.width}px`)}
              >
                {summary[col.field]}
              </Box>
            );
          })}
          <Box className="MuiDataGrid-cell" />
        </Box>
      )}

      <DataGridPagination count={totalDataCount} page={page} onPageChange={setPage} />
    </GridFooterContainer>
  );
};

export default AccountingReportFooter;
