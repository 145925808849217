import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useDefaultFiltersData } from 'hooks/useDefaultFiltersData';
import * as atoms from './accountingFiltersAtoms';

const defaultSelectedFilter = (selected: string[], filters: { id: string }[]) =>
  selected.filter((id) => filters.some((filter) => filter.id === id));

export const useFetchAccountingFilters = () => {
  const { filtersData } = useDefaultFiltersData('fetchAccountingFiltersData');

  const setClients = useSetAtom(atoms.clientsAtoms.data);
  const setOperators = useSetAtom(atoms.operatorsAtoms.data);
  const setClusters = useSetAtom(atoms.clustersAtoms.data);
  const setGames = useSetAtom(atoms.gamesAtoms.data);
  const setCountries = useSetAtom(atoms.countriesAtoms.data);
  const setCurrencies = useSetAtom(atoms.currenciesAtoms.data);

  const setSelectedClients = useSetAtom(atoms.clientsAtoms.selected);
  const setSelectedOperators = useSetAtom(atoms.operatorsAtoms.selected);
  const setSelectedClusters = useSetAtom(atoms.clustersAtoms.selected);
  const setSelectedGames = useSetAtom(atoms.gamesAtoms.selected);
  const setSelectedCountries = useSetAtom(atoms.countriesAtoms.selected);
  const setSelectedCurrencies = useSetAtom(atoms.currenciesAtoms.selected);

  useEffect(() => {
    if (filtersData) {
      setClients(filtersData.clients);
      setSelectedClients((selected) => defaultSelectedFilter(selected, filtersData.clients));

      setOperators(filtersData.operators);
      setSelectedOperators((selected) => defaultSelectedFilter(selected, filtersData.operators));

      setClusters(filtersData.clusters);
      setSelectedClusters((selected) => defaultSelectedFilter(selected, filtersData.clusters));

      setCountries(filtersData.countries);
      setSelectedCountries((selected) => defaultSelectedFilter(selected, filtersData.countries));

      setCurrencies(filtersData.currencies);
      setSelectedCurrencies((selected) => defaultSelectedFilter(selected, filtersData.currencies));

      setGames(filtersData.games);
      setSelectedGames((selected) => defaultSelectedFilter(selected, filtersData.games));
    }
  }, [filtersData]);
};
