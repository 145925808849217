import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
  Alert,
  Button,
  ConfirmationPopover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SearchInput,
  TextField,
} from 'components';
import { type IAccountingFilterGroup, type TFilterAtoms } from 'hooks/useAccountingFilters';
import { showToast } from 'utils';
import AccountingReportFiltersActionButtons from './AccountingReportFiltersActionButtons';
import AccountingReportFiltersList from './AccountingReportFiltersList';

interface IProps {
  group: IAccountingFilterGroup;
  title: string;
  close: () => void;
  atoms: TFilterAtoms;
}

export const AccountingReportFiltersGroupEditor = ({ title, close, group, atoms }: IProps) => {
  const { t } = useTranslation();
  const selectedAtom = useMemo(() => atom(group.values), []);
  const selected = useAtomValue(selectedAtom);
  const data = useAtomValue(atoms.data);
  const [groups, setGroups] = useAtom(atoms.groups);
  const [search, setSearch] = useState('');
  const [name, setName] = useState(group.name);
  const dataToShow = useMemo(() => {
    return data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, data]);

  const [openDeletePopover, setOpenDeletePopover] = useState(false);
  const [error, setError] = useState('');
  const [isNewGroup, setIsNewGroup] = useState(!groups.find((g) => g.id === group.id));
  const [touched, setTouched] = useState(false);
  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);

  const sortAndSetGroups = useCallback((newGroups: IAccountingFilterGroup[]) => {
    setGroups([...newGroups].sort((a, b) => a.name.localeCompare(b.name)));
  }, []);

  useEffect(() => {
    if (selected.length < 2) {
      setTouched(false);
      return;
    }
    if (
      name.trim() !== group.name ||
      group.values.length !== selected.length ||
      !group.values.every((value) => selected.includes(value))
    ) {
      setTouched(true);
      return;
    }

    setTouched(false);
  }, [selected, name]);

  const handleSave = useCallback(() => {
    const updatedGroup = {
      ...group,
      name: name.trim(),
      values: [...selected],
    };

    if (!updatedGroup.name) {
      setError(t('accounting.groupNameRequired'));
      return;
    }
    const groupWithSameName = groups.find((group) => group.name === updatedGroup.name && group.id !== updatedGroup.id);
    if (groupWithSameName) {
      setError(t('accounting.groupNameExists'));
      return;
    }
    if (isNewGroup) {
      sortAndSetGroups([...groups, updatedGroup]);
      showToast({ title: t('accounting.groupCreated') });
      setIsNewGroup(false);
    } else {
      sortAndSetGroups(groups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)));
      showToast({ title: t('accounting.groupUpdated') });
    }
    setTouched(false);
    setError('');
  }, [name, group, selected]);

  const handleDelete = useCallback(() => {
    sortAndSetGroups(groups.filter((el) => el.id !== group.id));
    showToast({ title: t('accounting.groupDeleted') });
    close();
  }, [groups, group]);

  return (
    <Dialog open onClose={close}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 350 }}>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginTop: 1 }}
          label={t('accounting.groupName')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <SearchInput value={search} onValueChange={setSearch} />
        <AccountingReportFiltersActionButtons atoms={{ ...atoms, selected: selectedAtom }} dataToShow={dataToShow} />
        <Divider />
        <AccountingReportFiltersList atoms={{ ...atoms, selected: selectedAtom }} dataToShow={dataToShow} />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button disabled={!touched} onClick={handleSave}>
          {t('base.save')}
        </Button>
        <ConfirmationPopover
          anchorEl={deleteButtonRef.current}
          open={openDeletePopover}
          close={(confirmed: boolean) => {
            setOpenDeletePopover(false);
            confirmed && handleDelete();
          }}
        />
        <Button disabled={isNewGroup} color="error" ref={deleteButtonRef} onClick={() => setOpenDeletePopover(true)}>
          {t('base.delete')}
        </Button>
        <Button onClick={close} variant="outlined">
          {t('base.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountingReportFiltersGroupEditor;
