import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks';

interface IProps {
  betId: string | number | null;
  expandFreeSpins?: boolean;
  elevation?: number;
}

type TPostMessageValue = string | number | boolean | null | undefined;

export const GameHistoryViewer = ({ betId, expandFreeSpins, elevation }: IProps) => {
  const { darkMode } = useTheme();
  const { i18n } = useTranslation();
  const ref = useRef<HTMLIFrameElement>(null);

  const postMessage = useCallback((type: string, value: TPostMessageValue) => {
    ref.current?.contentWindow?.postMessage({ type, value }, import.meta.env.VITE_GAME_HISTORY_VIEWER_URL);
  }, []);

  useEffect(() => {
    ref?.current?.addEventListener('load', () => {
      postMessage('betId', betId);
      postMessage('darkMode', darkMode);
      postMessage('expandFreeSpins', Boolean(expandFreeSpins));
      postMessage('lang', i18n.language);
      postMessage('elevation', elevation || 0);
    });
  }, []);

  useEffect(() => postMessage('betId', betId), [betId]);
  useEffect(() => postMessage('darkMode', darkMode), [darkMode]);
  useEffect(() => postMessage('expandFreeSpins', Boolean(expandFreeSpins)), [expandFreeSpins]);
  useEffect(() => postMessage('lang', i18n.language), [i18n.language]);
  useEffect(() => postMessage('elevation', elevation || 0), [elevation]);

  const initialSrc = useMemo(() => {
    let src = import.meta.env.VITE_GAME_HISTORY_VIEWER_URL;
    src += `?darkMode=${Boolean(darkMode)}`;
    src += `&expandFreeSpins=${Boolean(expandFreeSpins)}`;
    src += `&betId=${betId || ''}`;
    src += `&lang=${i18n.language}`;
    return src;
  }, []);

  return (
    <iframe ref={ref} src={initialSrc} style={{ width: '100%', height: '100%', outline: 'none', border: 'none' }} />
  );
};

export default GameHistoryViewer;
