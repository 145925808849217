import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { TablePagination } from 'components';
import { useLoading } from 'hooks';

interface IProps {
  count: number;
  page: number;
  onPageChange: (page: number) => void;
}

export const dataGridRowsPerPageAtom = atomWithStorage('dataGridRowsPerPage', 25, undefined, { getOnInit: true });

export const DataGridPagination = ({ count, page, onPageChange }: IProps) => {
  const { loading } = useLoading();
  const [rowsPerPage, setRowsPerPage] = useAtom(dataGridRowsPerPageAtom);

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      setRowsPerPage((currentRowsPerPage) => {
        onPageChange(Math.floor((page * currentRowsPerPage) / newRowsPerPage));
        return newRowsPerPage;
      });
    },
    [page],
  );

  return (
    <TablePagination
      showFirstButton
      showLastButton
      disabled={loading}
      component="div"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={(_evt, page) => onPageChange(page)}
      onRowsPerPageChange={(evt) => handleRowsPerPageChange(Number(evt.target.value))}
      sx={{ overflow: 'hidden' }}
    />
  );
};

export default DataGridPagination;
