import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { type GridRowSelectionModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Box, Button, HeaderText, SearchInput } from 'components';
import { EUsersBulkAction, useUser, useUserManagement, type IUser } from 'hooks';
import { ERoutes } from 'routing/routes';
import TeamMembersApproveDelete from './TeamMembersApproveDelete';
import TeamMembersBulkActions from './TeamMembersBulkActions';
import TeamMembersTable, { statusTranslateKeyMap } from './TeamMembersTable';

export const Team = () => {
  const { t } = useTranslation();
  const { handleUsersBulkAction, rawData } = useUserManagement();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const currentUser = useUser();
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, usersToDelete: [] as string[] });

  const data: IUser[] = useMemo(() => {
    if (!rawData) return [];

    return rawData.users
      .filter((user: IUser) => user.id !== currentUser.id)
      .map((user: IUser) => ({
        id: user.id,
        email: user.email,
        status: t(statusTranslateKeyMap[user.status]),
        createdAt: dayjs(user.createdAt).format('YYYY-MM-DD HH:mm:ss z'),
        lastActivity: user.lastActiveAt && dayjs(user.lastActiveAt).format('YYYY-MM-DD HH:mm:ss z'),
        action: '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
      }));
  }, [rawData]);

  const dataToShow = useMemo(() => {
    if (!search) {
      return data;
    }
    const searchString = search.toLowerCase().trim();
    return data.filter((user) =>
      Boolean(Object.values(user).find((value: string) => value?.toLowerCase().includes(searchString))),
    );
  }, [data, search]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <HeaderText ignoreDrawer>{t('team.title')}</HeaderText>
      <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
        <SearchInput value={search} onValueChange={setSearch} />
        <Button size="small" onClick={() => navigate(ERoutes.ManagementTeamMembersDetails.replace(':id', 'add'))}>
          <PersonAddAltIcon fontSize="small" sx={{ mr: 1 }} />
          {t('team.addUser')}
        </Button>
      </Box>
      <TeamMembersBulkActions
        disabled={selected.length === 0}
        onActivate={() => handleUsersBulkAction(EUsersBulkAction.ACTIVE, selected as string[])}
        onDeactive={() => handleUsersBulkAction(EUsersBulkAction.DEACTIVE, selected as string[])}
        onDelete={() => setDeleteConfirm({ open: true, usersToDelete: selected as string[] })}
      />
      <TeamMembersTable
        data={dataToShow}
        selected={selected}
        setSelected={setSelected}
        onActivate={(userId) => handleUsersBulkAction(EUsersBulkAction.ACTIVE, [userId])}
        onDeactive={(userId) => handleUsersBulkAction(EUsersBulkAction.DEACTIVE, [userId])}
        onDelete={(userId) => setDeleteConfirm({ open: true, usersToDelete: [userId] })}
      />
      <TeamMembersApproveDelete
        usersToDelete={deleteConfirm.usersToDelete}
        open={deleteConfirm.open}
        onConfirm={() => {
          handleUsersBulkAction(EUsersBulkAction.DELETE, deleteConfirm.usersToDelete);
          setDeleteConfirm((s) => ({ ...s, open: false }));
        }}
        onClose={() => setDeleteConfirm((s) => ({ ...s, open: false }))}
        data={rawData?.users || []}
      />
    </Box>
  );
};

export default Team;
