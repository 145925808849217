import { atom } from 'jotai';

export const noPerms = {
  loaded: false,
  reports: false,
  reportsAccounting: false,
  reportsAccountingExport: false,
  reportsPlayerBrowser: false,
  reportsGameBrowser: false,
  team: false,
  clients: false,
  clusters: false,
};

export type Perms = typeof noPerms;
export interface IAuthData {
  email: string;
  password: string;
}

export const permsAtom = atom(noPerms);
export const userIdAtom = atom<string | null>(null);
export const isAuthorizedAtom = atom(false);
