import { forwardRef, type ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Input } from 'components';

interface IProps {
  value: string;
  onValueChange: (value: string) => void;
}

// eslint-disable-next-line react/display-name
export const ButtonInputSearch = forwardRef(({ value, onValueChange }: IProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { t } = useTranslation();

  return (
    <Input
      value={value}
      onChange={(evt) => onValueChange(evt.target.value)}
      sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}
      inputRef={ref}
      placeholder={t('base.placeholderSearch')}
      endAdornment={
        value ? (
          <IconButton size="small" onClick={() => onValueChange('')}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ) : (
          <SearchIcon fontSize="small" sx={{ marginRight: 0.5 }} />
        )
      }
    />
  );
});

export default ButtonInputSearch;
