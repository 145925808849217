import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGrid,
  GridCell,
  GridColumnMenu,
  GridOverlay,
  useGridApiRef,
  type GridRenderCellParams,
  type GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useSetAtom } from 'jotai';
import { DataGridBox, DataGridToolbar, IconButton, menuCellAtom, TableContextMenu, Tooltip } from 'components';
import { useLoading } from 'hooks';
import { EUserStatus } from 'hooks/useUserManagement';
import { ERoutes } from 'routing';
import { densityMap } from 'utils';

interface IProps {
  data: object[];
  selected: GridRowSelectionModel;
  setSelected: (selected: GridRowSelectionModel) => void;
  onActivate: (userId: string) => void;
  onDeactive: (userId: string) => void;
  onDelete: (userId: string) => void;
}

export const statusTranslateKeyMap = {
  [EUserStatus.ACTIVE]: 'team.active',
  [EUserStatus.DEACTIVE]: 'team.deactive',
  [EUserStatus.PENDING]: 'team.pending',
};

export const TeamMembersTable = ({ data, selected, setSelected, onActivate, onDeactive, onDelete }: IProps) => {
  const apiRef = useGridApiRef();
  const { i18n, t } = useTranslation();
  const { loading } = useLoading();
  const setMenuCell = useSetAtom(menuCellAtom);
  const navigate = useNavigate();
  const navigateToUser = useCallback(
    (id: string, newTab?: boolean) => {
      const path = `${ERoutes.ManagementTeamMembersDetails.replace(':id', id)}`;
      newTab ? window.open(path, '_blank') : navigate(path);
    },
    [navigate],
  );

  const columns = useMemo(
    () => [
      { field: 'email', headerName: t('team.email') },
      { field: 'status', headerName: t('team.status') },
      { field: 'createdAt', headerName: t('team.createdAt') },
      { field: 'lastActivity', headerName: t('team.lastActivity') },
      {
        editable: false,
        field: 'action',
        disableColumnMenu: true,
        sortable: false,
        headerName: t('playerBrowser.action'),
        renderCell: (props: GridRenderCellParams) => (
          <>
            {(
              [
                {
                  onClick: () => navigateToUser(props.row.id),
                  IconComponent: VisibilityIcon,
                  key: 'details',
                  color: 'inherit',
                  visible: true,
                },
                {
                  onClick: () => onActivate(props.row.id),
                  IconComponent: PersonIcon,
                  key: 'activate',
                  color: 'success',
                  visible: props.row.status === t(statusTranslateKeyMap[EUserStatus.DEACTIVE]),
                },
                {
                  onClick: () => onDeactive(props.row.id),
                  IconComponent: PersonOffIcon,
                  key: 'deactivate',
                  color: 'info',
                  visible: props.row.status === t(statusTranslateKeyMap[EUserStatus.ACTIVE]),
                },
                {
                  onClick: () => onDelete(props.row.id),
                  IconComponent: DeleteIcon,
                  key: 'delete',
                  color: 'error',
                  visible: true,
                },
              ] as const
            ).map(
              ({ onClick, IconComponent, key, color, visible }) =>
                visible && (
                  <Tooltip key={key} title={t('team.' + key)}>
                    <IconButton
                      color={color}
                      size={densityMap[apiRef.current?.state.density] || 'medium'}
                      onClick={onClick}
                    >
                      <IconComponent fontSize={densityMap[apiRef.current?.state.density] || 'medium'} />
                    </IconButton>
                  </Tooltip>
                ),
            )}
          </>
        ),
      },
    ],
    [i18n.language, t],
  );

  return (
    <DataGridBox height={'calc(100vh - 300px)'}>
      <DataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        disableRowSelectionOnClick
        loading={loading}
        disableColumnFilter
        isCellEditable={() => false}
        checkboxSelection
        sortingMode="server"
        editMode="row"
        hideFooter={data.length === 0}
        disableColumnSorting={loading}
        rowSelectionModel={selected}
        onRowSelectionModelChange={setSelected}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        slots={{
          noRowsOverlay: () => <GridOverlay>{t('base.NoData')}</GridOverlay>,
          toolbar: () => <DataGridToolbar localStorageKey="management-team" />,
          columnMenu: (props) => <GridColumnMenu {...props} slots={{ columnMenuColumnsItem: null }} />,
          cell: (props) => {
            return (
              <GridCell
                {...props}
                onContextMenu={(evt: MouseEvent) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (props.column.field === 'action') {
                    return;
                  }
                  setMenuCell({ rowId: props.rowId, column: props.column });
                }}
              />
            );
          },
        }}
      />
      <TableContextMenu apiRef={apiRef} navigateToDetails={navigateToUser} detailsFieldId="id" />
    </DataGridBox>
  );
};

export default TeamMembersTable;
