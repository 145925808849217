import { useAtom } from 'jotai';
import {
  currencyDateAtom,
  normalizeCurrencyAtom,
  normalizeEnabledAtom,
  normalizeGroupByAtom,
} from './accountingFiltersAtoms';

export const useAccountingFiltersNormalize = () => {
  const [enabled, setEnabled] = useAtom(normalizeEnabledAtom);
  const [currency, setCurrency] = useAtom(normalizeCurrencyAtom);
  const [groupBy, setGroupBy] = useAtom(normalizeGroupByAtom);
  const [currencyDate, setCurrencyDate] = useAtom(currencyDateAtom);

  return {
    enabled,
    setEnabled,
    currency,
    setCurrency,
    groupBy,
    setGroupBy,
    currencyDate,
    setCurrencyDate,
  };
};

export default useAccountingFiltersNormalize;
