import { permsAtom } from 'auth';
import { useAtomValue } from 'jotai';

export const parsePermsArray = (arr: string[]) => ({
  loaded: true,
  reportsAccounting: arr.includes('view_report_transactions'),
  reportsPlayerBrowser: arr.includes('view_combined_transactions_by_players'),
  clients: arr.includes('view_clients'),
  clusters: arr.includes('view_clusters'),
  reportsAccountingExport: arr.includes('export_report_transactions_csv'),
  team: arr.includes('view_users') || true,
  // todo
  reports: true,
  reportsGameBrowser: true,
});

export const usePerms = () => {
  const perms = useAtomValue(permsAtom);
  return perms;
};
