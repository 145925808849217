import { useEffect, type MutableRefObject } from 'react';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';

const LOCAL_STORAGE_KEY_PREFIX = 'x-data-grid-coolumns-visibility--';
export const useSaveDataGridColumnsVisibility = (
  localStorageKey: string,
  apiRef: MutableRefObject<GridApiCommunity>,
) => {
  useEffect(() => {
    try {
      const columnsVisibilityJson = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey);
      if (columnsVisibilityJson) {
        const columnsVisibility = JSON.parse(columnsVisibilityJson);
        const columns = apiRef.current.getAllColumns();
        columns.forEach((column) => {
          const savedVisibility = columnsVisibility?.[column.field];
          if (typeof savedVisibility === 'boolean') {
            apiRef.current.setColumnVisibility(column.field, savedVisibility);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }

    return apiRef.current.subscribeEvent('columnVisibilityModelChange', (columnsVisibility) => {
      localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey, JSON.stringify(columnsVisibility));
    });
  });
};
