import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const timezones = Intl.supportedValuesOf('timeZone').map((timezone) => ({
  name: timezone,
  offset: dayjs().tz(timezone).format('Z'),
}));

interface IProps {
  value: string;
  onChange: (value: string) => void;
  minimalistic?: boolean;
}

export const TimezoneSelector = ({ value, onChange, minimalistic }: IProps) => {
  const { t } = useTranslation();
  const autocompleteValue = useMemo(() => {
    return timezones.find((el) => el.name === value);
  }, [value]);
  return (
    <Autocomplete
      options={timezones}
      value={autocompleteValue}
      disableClearable
      onChange={(_, newValue) => newValue && onChange(newValue.name)}
      getOptionLabel={(option) => `${option.offset} ${option.name} `}
      popupIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
      renderInput={(params) =>
        minimalistic ? (
          <TextField
            {...params}
            size="small"
            variant="standard"
            sx={{ 'p': 0, '& ::before': { border: 'none !important' } }}
            inputProps={{
              ...params.inputProps,
              sx: { color: 'white' },
            }}
          />
        ) : (
          <TextField {...params} size="small" variant="outlined" label={t('accounting.timezone')} />
        )
      }
    />
  );
};

export default TimezoneSelector;
