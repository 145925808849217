import { Avatar } from 'components';

interface IProps {
  src: string;
  alt: string;
  shadow?: boolean;
}

export const LanguageSelectorAvatar = ({ src, alt, shadow }: IProps) => {
  return (
    <Avatar
      src={src}
      alt={alt}
      sx={{
        width: 20,
        height: 20,
        boxShadow: shadow ? '0px 0px 4px 0px rgba(0, 0, 0, 1)' : 'none',
      }}
    />
  );
};

export default LanguageSelectorAvatar;
