import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Button, Dialog, DialogTitle, List, ListItemText, Tooltip, Typography } from 'components';
import { EUserStatus, type IUser } from 'hooks';

interface IProps {
  data: IUser[];
  onConfirm: () => void;
  onClose: () => void;
  usersToDelete: string[];
  open: boolean;
}

export const TeamMembersApproveDelete = ({ usersToDelete, onConfirm, onClose, data, open }: IProps) => {
  const { t } = useTranslation();

  const { users, allBlocked } = useMemo(() => {
    const users = usersToDelete.map((id) => data.find((el) => el.id === id)).filter(Boolean) as IUser[];
    return { users, allBlocked: users.every((user) => user.status === EUserStatus.DEACTIVE) };
  }, [data, usersToDelete]);

  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ sx: { p: 1 } }}>
      <DialogTitle>{t('team.areYouSureDelete')}</DialogTitle>
      <List sx={{ maxHeight: '60dvh', overflow: 'auto', ml: 4 }}>
        {users.map((user) => {
          return (
            <ListItemText
              key={user.id}
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={user.status === EUserStatus.DEACTIVE ? t('team.deactive') : t('team.active')}>
                    {user.status === EUserStatus.DEACTIVE ? (
                      <PersonOffIcon color="info" />
                    ) : (
                      <PersonIcon color="success" />
                    )}
                  </Tooltip>
                  {user.email}
                </Box>
              }
              disableTypography
            />
          );
        })}
      </List>
      {!allBlocked && (
        <Typography color="error" textAlign="center" sx={{ mb: 1 }}>
          {t('team.youCanDeleteOnlyDeactive')}
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <Button onClick={onConfirm} color="error" sx={{ width: '50%' }} disabled={!allBlocked}>
          {t('base.delete')}
        </Button>
        <Button onClick={onClose} variant="outlined" sx={{ width: '50%' }}>
          {t('base.close')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default TeamMembersApproveDelete;
