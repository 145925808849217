import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthorizedAtom, signIn, signOut, userIdAtom, type IAuthData } from 'auth';
import { useAtom, useAtomValue } from 'jotai';
import { ERoutes } from 'routing';
import { useSetLoading } from './useLoading';

let initialPath = window.location.pathname + window.location.search;

export const useAuth = () => {
  const [userId] = useAtom(userIdAtom);
  const navigate = useNavigate();
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const { startLoading, endLoading } = useSetLoading();

  const signOutWrapper = async () => {
    try {
      startLoading('singOut');
      await signOut();
      endLoading('singOut');
    } catch (err) {
      console.error(err);
      endLoading('singOut');
      setErrorKey('base.baseError');
    }
  };

  const signInWrapper = async (values: IAuthData): Promise<void> => {
    try {
      startLoading('signIn');
      const response = await signIn(values);
      endLoading('signIn');
      if (response.status === 'OK') {
        setErrorKey(null);
        if (initialPath) {
          navigate(initialPath);
          initialPath = '';
        } else {
          navigate(ERoutes.Default);
        }
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        setErrorKey('login.credentialsError');
      }
    } catch (err) {
      console.error(err);
      endLoading('signIn');
      setErrorKey('base.baseError');
    }
  };

  return {
    signOut: signOutWrapper,
    signIn: signInWrapper,
    isAuthorized,
    userId,
    errorKey,
  };
};
