import { useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from 'components';

export const BreadcrumbsNavigation = () => {
  const { pathname } = useLocation();
  const breadcrumbs = pathname.split('/').filter(Boolean);

  return (
    <Box>
      <Breadcrumbs color="inherit" sx={{ ml: 3, mt: 10, position: 'absolute' }}>
        {breadcrumbs.map((key) => (
          <Typography key={key} color="inherit">
            {key}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsNavigation;
