import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material';
import * as rawMuiLocales from '@mui/material/locale';
import * as rawMuiDataGridLocales from '@mui/x-data-grid/locales';
import * as rawMuiDatePickerLocales from '@mui/x-date-pickers/locales';
import { useUserPreferences } from './useUserPreferences';

const muiLocales: Record<string, typeof rawMuiLocales.enUS> = {};
Object.assign(muiLocales, rawMuiLocales);
const datePickerLocales: Record<string, typeof rawMuiDatePickerLocales.enUS> = {};
Object.assign(datePickerLocales, rawMuiDatePickerLocales);
const dataGridLocales: Record<string, typeof rawMuiDataGridLocales.enUS> = {};
Object.assign(dataGridLocales, rawMuiDataGridLocales);

export const useTheme = () => {
  const { i18n } = useTranslation();
  const {
    userPreferences: { darkMode },
    setUserPreference,
  } = useUserPreferences();
  const toggleColorMode = (): void => setUserPreference('darkMode', (prevMode) => !prevMode);

  const theme = useMemo(() => {
    const languageKey = i18n.language?.replace('-', '');
    return createTheme(
      {
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? '#A1987E' : '#867C5F',
          },
        },
      },
      muiLocales[languageKey] || muiLocales['enUS'],
      datePickerLocales[languageKey] || datePickerLocales['enUS'],
      dataGridLocales[languageKey] || dataGridLocales['enUS'],
    );
  }, [darkMode, i18n.language]);

  return { toggleColorMode, darkMode, theme };
};
