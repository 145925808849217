import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColumnMenu, GridOverlay, useGridApiRef } from '@mui/x-data-grid';
import { DataGridBox, DataGridToolbar } from 'components';
import { useAccountingData, useLoading, usePerms } from 'hooks';
import AccountingReportExport from './AccountingReportExport';
import AccountingReportFooter from './AccountingReportFooter';
import { generateColumns } from './generateAccountingReportTableColumns';

export const AccountingReportTable = () => {
  const { i18n, t } = useTranslation();
  const perms = usePerms();
  const apiRef = useGridApiRef();
  const { data, totalDataCount, page, setPage, setSort, sort, summary } = useAccountingData();
  const { loading } = useLoading();
  const columns = useMemo(() => generateColumns(t, perms), [i18n.language, perms]);

  return (
    <DataGridBox>
      <DataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        disableColumnFilter
        isCellEditable={() => false}
        sortingMode="server"
        editMode="row"
        sortModel={sort}
        onSortModelChange={setSort}
        hideFooter={data.length === 0}
        disableColumnSorting={loading}
        getCellClassName={(params) => {
          if (params.field === 'ggr' && params.value !== '0.00') {
            return params.value[0] === '-' ? 'ggr-negative' : 'ggr-positive';
          }
          return '';
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .ggr-positive': {
            color: 'success.main',
          },
          '& .ggr-negative': {
            color: 'error.main',
          },
        }}
        slots={{
          noRowsOverlay: () => <GridOverlay>{t('base.NoData')}</GridOverlay>,
          toolbar: () => (
            <DataGridToolbar
              localStorageKey="accounting-report"
              additionalColumnElements={summary as unknown as Record<string, string>}
            >
              <AccountingReportExport sort={sort} />
            </DataGridToolbar>
          ),
          footer: () => (
            <AccountingReportFooter
              summary={summary as unknown as Record<string, string>}
              totalDataCount={totalDataCount}
              page={page}
              setPage={setPage}
            />
          ),
          columnMenu: (props) => <GridColumnMenu {...props} slots={{ columnMenuColumnsItem: null }} />,
        }}
      />
    </DataGridBox>
  );
};

export default AccountingReportTable;
