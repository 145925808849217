import { useCallback } from 'react';
import { type DocumentNode, type OperationVariables, type QueryResult } from '@apollo/client';
import { client } from 'apollo';
import { t } from 'i18next';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { showError } from 'utils';

const generatingReportAtom = atom(false);
const generatedReportUrlAtom = atom('');

const generate = async (
  query: DocumentNode,
  variables: OperationVariables,
  getUrl: (result: QueryResult) => string,
) => {
  const store = getDefaultStore();
  store.set(generatingReportAtom, true);

  try {
    const data = (await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    })) as QueryResult;
    const url = getUrl(data);
    if (!url) {
      throw new Error(t('reportExport.reportFailed'));
    }
    store.set(generatedReportUrlAtom, url);
  } catch (e) {
    store.set(generatedReportUrlAtom, '');
    showError(e as Error);
  }
  store.set(generatingReportAtom, false);
};

export const useReportExport = () => {
  const [generatingReport, setGeneratingReport] = useAtom(generatingReportAtom);
  const [generatedReportUrl, setGeneratedReportUrl] = useAtom(generatedReportUrlAtom);

  const clear = useCallback(() => {
    setGeneratingReport(false);
    setGeneratedReportUrl('');
  }, []);

  return {
    generatingReport,
    generatedReportUrl,
    clear,
    generate,
  };
};
