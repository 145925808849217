import { useAtomValue } from 'jotai';
import { Box, Typography } from 'components';
import { type TFilterAtoms } from 'hooks/useAccountingFilters';

interface IProps {
  title: string;
  atoms: TFilterAtoms;
}

export const AccountingReportFilterTitle = ({ title, atoms }: IProps) => {
  const selected = useAtomValue(atoms.selected);
  const data = useAtomValue(atoms.data);

  return (
    <Typography sx={{ borderBottom: '1px solid', paddingBottom: 1, borderColor: 'primary.main', marginBottom: 1 }}>
      <b>{title}</b>
      {data.length > 0 && (
        <Box component="span" sx={{ color: 'primary.main' }}>{` (${selected.length}/${data.length})`}</Box>
      )}
    </Typography>
  );
};

export default AccountingReportFilterTitle;
