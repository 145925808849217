import type { GridColDef } from '@mui/x-data-grid';
import type { Perms } from 'auth';
import type { TFunction } from 'i18next';

export const generateColumns = (t: TFunction, perms: Perms) =>
  [
    perms.clusters && { editable: false, field: 'clusterLabel', headerName: t('accounting.cluster') },
    perms.clients && { editable: false, field: 'clientLabel', headerName: t('accounting.client') },
    { editable: false, field: 'operatorLabel', headerName: t('accounting.operator') },
    { editable: false, field: 'slotLabel', headerName: t('accounting.game') },
    { editable: false, field: 'currency', headerName: t('accounting.currency') },
    { editable: false, field: 'country', headerName: t('accounting.country') },
    { editable: false, field: 'debitTotal', headerName: t('accounting.bet') },
    { editable: false, field: 'creditTotal', headerName: t('accounting.win') },
    { editable: false, field: 'refundTotal', headerName: t('accounting.refund') },
    { editable: false, field: 'transactionsCount', headerName: t('accounting.spin') },
    { editable: false, field: 'totalBuyBet', headerName: t('accounting.buyBet') },
    { editable: false, field: 'totalBuyCount', headerName: t('accounting.buyBetCount') },
    { editable: false, field: 'ggr', headerName: t('accounting.ggr') },
  ].filter(Boolean) as GridColDef[];
