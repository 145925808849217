import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useAccountingFiltersValidate, useLoading, type TFilterAtoms } from 'hooks';

interface IProps {
  filtersData: { title: string; atoms: TFilterAtoms }[];
}

export const AccountingReportFiltersApplyButton = ({ filtersData }: IProps) => {
  const { t } = useTranslation();
  const { isError, validate } = useAccountingFiltersValidate(filtersData);
  const { loading } = useLoading();

  return (
    <Button disabled={isError || loading} onClick={validate} size="large">
      {t('base.apply')}
    </Button>
  );
};

export default AccountingReportFiltersApplyButton;
