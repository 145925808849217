export interface IAccountingFilterData {
  id: string;
  name: string;
}

export interface IAccountingFilterGroup {
  name: string;
  id: string;
  values: string[];
}

export enum ENormalizePossibleCurrencies {
  EUR = 'EUR',
}

export enum ENormalizeGroups {
  NONE = 'none',
  COUNTRIES = 'country',
  OPERATORS = 'operatorId',
  GAMES = 'slotId',
  CURRENCIES = 'currency',
  CLIENTS = 'clientId',
  CLUSTERS = 'clusterId',
}

export enum EFilterTesters {
  ALL = 'ALL',
  TESTERS_ONLY = 'TESTERS_ONLY',
  REAL_ONLY = 'REAL_ONLY',
}
