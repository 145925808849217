import { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button, { type ButtonProps } from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { PopperMenu, type IPopperMenuProps } from 'components/PopperMenu';

interface IProps extends Omit<ButtonProps, 'onChange'> {
  popperProps?: Partial<IPopperMenuProps>;
  value: string;
  options: { label: string; key: string }[];
  onChange: (value: string) => void;
}

export const PopperSelect = ({ disabled, options, popperProps, sx, value, onChange, ...rest }: IProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const currentOption = options.find((option) => option.key === value);

  return (
    <>
      <Button
        disabled={disabled}
        ref={buttonRef}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: 'text.primary',
          justifyContent: 'space-between',
          pl: 1.5,
          pr: 1,
          fontSize: (theme) => theme.typography.body1.fontSize,
          height: 40,
          ...sx,
        }}
        onClick={() => setOpen(true)}
        {...rest}
      >
        {currentOption?.label}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ ml: 1, pointerEvents: 'none' }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ ml: 1, pointerEvents: 'none' }} />
        )}
      </Button>
      <PopperMenu
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        {...popperProps}
        sx={{
          width: buttonRef.current?.offsetWidth,
          ...popperProps?.sx,
        }}
      >
        {options.map((option) => (
          <MenuItem
            selected={option.key === value}
            key={option.key}
            value={option.key}
            onClick={(evt) => {
              evt.stopPropagation();
              setOpen(false);
              if (value !== option.key) {
                onChange(option.key);
              }
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </PopperMenu>
    </>
  );
};

export default PopperSelect;
