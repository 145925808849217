import { useEffect, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import type { GridColDef, GridRowId } from '@mui/x-data-grid';
import type { GridApiCommunity } from '@mui/x-data-grid/internals';
import { atom, useAtom } from 'jotai';
import { MenuItem, PopperMenu } from 'components';
import { changeFirstLetterCase, copyToClipboard } from 'utils';

interface IProps {
  apiRef: MutableRefObject<GridApiCommunity>;
  navigateToDetails: (id: string, newTab?: boolean) => void;
  detailsFieldId: string;
}

export const menuCellAtom = atom<null | { column: GridColDef; rowId: GridRowId }>(null);
export const TableContextMenu = ({ apiRef, navigateToDetails, detailsFieldId }: IProps) => {
  const [menuCell, setMenuCell] = useAtom(menuCellAtom);
  const { t } = useTranslation();
  useEffect(() => () => setMenuCell(null), []);

  return (
    <PopperMenu
      anchorEl={menuCell ? () => apiRef.current.getCellElement(menuCell.rowId, menuCell.column.field)! : null}
      open={Boolean(menuCell)}
      onClose={() => setMenuCell(null)}
      placement="bottom-start"
    >
      <MenuItem
        onClick={() => {
          if (menuCell) {
            copyToClipboard(apiRef.current.getCellValue(menuCell.rowId, menuCell.column.field));
          }
          setMenuCell(null);
        }}
      >
        {t('base.Copy')} {changeFirstLetterCase(menuCell?.column.headerName || '', 'lower')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (menuCell) {
            navigateToDetails(apiRef.current.getCellValue(menuCell.rowId, detailsFieldId));
          }
          setMenuCell(null);
        }}
      >
        {t('base.OpenDetails')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (menuCell) {
            navigateToDetails(apiRef.current.getCellValue(menuCell.rowId, detailsFieldId), true);
          }
          setMenuCell(null);
        }}
      >
        {t('base.OpenDetailsNewTab')}
      </MenuItem>
    </PopperMenu>
  );
};

export default TableContextMenu;
