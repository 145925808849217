import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Box, Divider, SearchInput, Typography } from 'components';
import { type TFilterAtoms } from 'hooks/useAccountingFilters';
import AccountingReportFiltersActionButtons from './AccountingReportFiltersActionButtons';
import AccountingReportFiltersGroup from './AccountingReportFiltersGroup';
import AccountingReportFiltersList from './AccountingReportFiltersList';
import AccountingReportFilterTitle from './AccountingReportFilterTitle';

interface IProps {
  title: string;
  atoms: TFilterAtoms;
}

export const AccountingReportFilter = ({ title, atoms }: IProps) => {
  const { t } = useTranslation();
  const isError = useAtomValue(atoms.error);
  const [search, setSearch] = useState('');
  const data = useAtomValue(atoms.data);
  const dataToShow = useMemo(() => {
    return data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, data]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 220 }}>
      <AccountingReportFilterTitle title={title} atoms={atoms} />
      <AccountingReportFiltersGroup title={title} atoms={atoms} />
      <SearchInput value={search} onValueChange={setSearch} />
      <AccountingReportFiltersActionButtons atoms={atoms} dataToShow={dataToShow} />
      <Divider />
      {isError && (
        <Typography color="error" textAlign="center" variant="body2" sx={{ height: 22 }}>
          {t('accounting.filterError')}
        </Typography>
      )}
      <AccountingReportFiltersList atoms={atoms} dataToShow={dataToShow} />
    </Box>
  );
};

export default AccountingReportFilter;
