import { useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
  GridPreferencePanelsValue,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext,
  type GridDensity,
} from '@mui/x-data-grid';
import { Button } from 'components';
import { useSaveDataGridColumnsVisibility, useSaveDataGridColumnsWidth, useUserPreferences } from 'hooks';

interface IProps extends PropsWithChildren {
  localStorageKey: string;
  additionalColumnElements?: Record<string, string>;
}

export const DataGridToolbar = ({ localStorageKey, children, additionalColumnElements }: IProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const { adjustColumnsWidth } = useSaveDataGridColumnsWidth(localStorageKey, apiRef, additionalColumnElements);
  useSaveDataGridColumnsVisibility(localStorageKey, apiRef);
  const { userPreferences, setUserPreference } = useUserPreferences();

  useEffect(() => {
    const savedDensity = localStorage.getItem('x-data-grid-column-density') || '';
    if (['compact', 'standard', 'comfortable'].includes(savedDensity)) {
      apiRef.current.setDensity(savedDensity as GridDensity);
    }

    return apiRef.current.subscribeEvent('densityChange', (density) => {
      localStorage.setItem('x-data-grid-column-density', density);
    });
  }, []);

  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <Button
        variant="text"
        size="small"
        onClick={() => apiRef.current.showPreferences(GridPreferencePanelsValue.columns)}
      >
        <ViewColumnIcon fontSize="small" sx={{ mr: 1 }} />
        {apiRef.current.getLocaleText('columnMenuManageColumns')}
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          setUserPreference('autoAdjustColumnsWidth', (prev) => {
            if (!prev) {
              adjustColumnsWidth();
            }
            return !prev;
          });
        }}
      >
        <AspectRatioIcon fontSize="small" sx={{ mr: 1 }} />
        {userPreferences.autoAdjustColumnsWidth ? t('base.autoColumnsWidth') : t('base.customColumnsWidth')}
      </Button>
      {children}
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;
