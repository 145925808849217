import { Outlet } from 'react-router-dom';
import { Box, BreadcrumbsNavigation, Header, LeftMenu } from 'components';
import { useAuth } from 'hooks';

export const Layout = () => {
  const { isAuthorized } = useAuth();

  return (
    <Box data-testid="layout" sx={{ display: 'flex' }}>
      {isAuthorized ? (
        <>
          <Header />
          <LeftMenu />
          <BreadcrumbsNavigation />
          <Box
            sx={{
              flexGrow: 1,
              pb: 4,
              marginTop: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Outlet />
          </Box>
        </>
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default Layout;
