import { atom, useAtomValue } from 'jotai';

export const userAtom = atom({
  email: '',
  role: '',
  status: '',
  id: '',
});

export const useUser = () => {
  const user = useAtomValue(userAtom);
  return user;
};
