const canvas = document.createElement('canvas');
const context = canvas.getContext('2d')!;

export const measureText = (text: string, font: string) => {
  context.font = font;
  return context.measureText(text);
};

export function getComputedStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export const getCanvasFont = (element: HTMLElement) => {
  const fontWeight = getComputedStyle(element, 'font-weight') || 'normal';
  const fontSize = getComputedStyle(element, 'font-size') || '16px';
  const fontFamily = getComputedStyle(element, 'font-family') || 'Roboto';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};
