import { useEffect, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColumnMenu, useGridApiRef, type GridColDef, type GridSortModel } from '@mui/x-data-grid';
import { DataGridBox, DataGridPagination, DataGridToolbar } from 'components';
import { useLoading } from 'hooks';
import { generateColumns } from './playerBrowserDetailsGenerateColumn';

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: object[];
  totalCount: number;
  selectedBetId: string | number | null;
  setSelectedBetId: Dispatch<SetStateAction<string | number | null>>;
}
export const PlayerBrowserDetailsTable = ({
  setSelectedBetId,
  selectedBetId,
  sort,
  setSort,
  totalCount,
  page,
  setPage,
  data,
}: IProps) => {
  const { t, i18n } = useTranslation();

  const apiRef = useGridApiRef();
  const { loading } = useLoading();
  const columns: GridColDef[] = useMemo(() => generateColumns(t), [i18n.language]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const nextRowIndexDiff = e.key === 'ArrowDown' ? 1 : e.key === 'ArrowUp' ? -1 : 0;
      if (nextRowIndexDiff) {
        setSelectedBetId((currentId) => {
          if (currentId) {
            const selectedRowIndex = apiRef.current?.getRowIndexRelativeToVisibleRows(currentId);
            const newRowId = apiRef.current?.getRowIdFromRowIndex(selectedRowIndex + nextRowIndexDiff);
            if (newRowId) {
              return newRowId;
            }
          }
          return currentId;
        });
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <DataGridBox>
      <DataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        disableColumnFilter
        isCellEditable={() => false}
        sortingMode="server"
        sortModel={sort}
        onSortModelChange={setSort}
        editMode="row"
        onRowSelectionModelChange={(e) => setSelectedBetId((currentId) => (currentId === e[0] ? null : e[0]))}
        rowSelectionModel={selectedBetId ? [selectedBetId] : []}
        hideFooter={data.length === 0}
        disableColumnSorting={loading}
        hideFooterSelectedRowCount
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        slots={{
          toolbar: () => <DataGridToolbar localStorageKey="player-browser-details" />,
          columnMenu: (props) => <GridColumnMenu {...props} slots={{ columnMenuColumnsItem: null }} />,
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,
        }}
      />
    </DataGridBox>
  );
};

export default PlayerBrowserDetailsTable;
