import { useMemo } from 'react';
import { PickersDay, type PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { type Dayjs } from 'dayjs';

interface ICustomPickersDayProps extends PickersDayProps<Dayjs> {
  from: Dayjs;
  to: Dayjs;
}

const notSelectedDaySx = {
  'transition': 'none',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};

const oneSelectedDay = {
  'backgroundColor': 'primary.main',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};

const selectedDaySx = {
  ...oneSelectedDay,
  borderRadius: 0,
};

const leftCornerSelectedDaySx = {
  ...selectedDaySx,
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
};

const rightCornerDaySx = {
  ...selectedDaySx,
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
};

const getPropperSx = (from: Dayjs, to: Dayjs, day: Dayjs) => {
  if (day.isBetween(from, to, 'day', '[]')) {
    if (from.isSame(to, 'day')) {
      return oneSelectedDay;
    }
    if (day.isSame(to, 'day')) {
      if (day.day() === 0) {
        return oneSelectedDay;
      }
      return rightCornerDaySx;
    }
    if (day.isSame(from, 'day')) {
      if (day.day() === 6) {
        return oneSelectedDay;
      }
      return leftCornerSelectedDaySx;
    }
    if (day.day() === 6) {
      return rightCornerDaySx;
    }
    if (day.day() === 0) {
      return leftCornerSelectedDaySx;
    }
    return selectedDaySx;
  }
  return notSelectedDaySx;
};

export const CustomPickersDay = (props: ICustomPickersDayProps) => {
  const { from, to, day, ...otherProps } = props;
  const sx = useMemo(() => getPropperSx(from, to, day), [from, to, day]);
  return <PickersDay {...otherProps} day={day} selected={false} disableMargin sx={sx} onFocus={() => false} />;
};

export default CustomPickersDay;
