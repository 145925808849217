import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { type TFunction } from 'i18next';
import { parseBigNumber, parseCountryCode } from 'utils';

interface IRawRecord {
  id: string;
  externalId: string;
  debitAmount: number;
  creditAmount: number;
  playerId: string;
  playerInternalId: string;
  playerExternalId: string;
  operatorLabel: string;
  clusterLabel?: string;
  clientLabel?: string;
  currency: string;
  country: string;
  tester: boolean;
  totalBuyCount: number;
  totalSpinCount: number;
  firstTransactionDate: string;
  lastTransactionDate: string;
}

export interface IPlayerBrowserRawData {
  combinedTransactionsByPlayers?: { items: IRawRecord[]; skip: number; take: number; totalCount: number };
}

export const GET_PLAYERS = gql`
  query GetPlayers(
    $where: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $withClusters: Boolean!
    $withClients: Boolean!
  ) {
    combinedTransactionsByPlayers(
      filter: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      totalCount
      skip
      take
      items {
        debitAmount
        creditAmount
        playerId
        playerExternalId
        playerInternalId
        operatorLabel
        clusterLabel @include(if: $withClusters)
        clientLabel @include(if: $withClients)
        currency
        country
        tester
        totalBuyCount
        firstTransactionDate
        lastTransactionDate
        totalSpinCount
      }
    }
  }
`;

export const parsePlayerBrowserData = (rawData: IPlayerBrowserRawData, t: TFunction, lang: string) => {
  if (!rawData.combinedTransactionsByPlayers) {
    return { totalCount: 0, data: [] };
  }

  return {
    totalCount: rawData.combinedTransactionsByPlayers.totalCount,
    data: rawData.combinedTransactionsByPlayers.items.map((item) => {
      return {
        id: crypto.randomUUID(),
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clusterLabel,
        currency: item.currency,
        country: parseCountryCode(item.country, lang),
        debitAmount: parseBigNumber(item.debitAmount),
        creditAmount: parseBigNumber(item.creditAmount),
        totalBuyCount: parseBigNumber(item.totalBuyCount, 0),
        totalSpinCount: parseBigNumber(item.totalSpinCount, 0),
        firstTransactionDate: dayjs(item.firstTransactionDate).format('YYYY-MM-DD HH:mm:ss z'),
        lastTransactionDate: dayjs(item.lastTransactionDate).format('YYYY-MM-DD HH:mm:ss z'),
        tester: item.tester ? t('base.Yes') : t('base.No'),
      };
    }),
  };
};
