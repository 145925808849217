import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton } from '@mui/material';
import { useTheme } from 'hooks';

export const ModeToggle = () => {
  const { darkMode, toggleColorMode } = useTheme();

  return (
    <IconButton color="inherit" onClick={toggleColorMode}>
      {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
};

export default ModeToggle;
