import { type ReactNode } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { type Perms } from 'auth';
import { ERoutes } from 'routing';

interface IMenuItem {
  key: string;
  icon: ReactNode;
  disabled?: boolean;
  routes: { key: string; route: ERoutes; disabled?: boolean }[];
  groups: { key: string; disabled?: boolean; routes: { key: string; route: ERoutes; disabled?: boolean }[] }[];
}

export const generateMenuData = (perms: Perms) => {
  const data: IMenuItem[] = [];

  if (perms.reports) {
    const groups: IMenuItem['groups'] = [];

    if (perms.reportsAccounting) {
      groups.push({
        key: 'menu.Financial',
        routes: [{ key: 'menu.Accounting', route: ERoutes.AccountingReport }],
      });
    }
    if (perms.reportsPlayerBrowser) {
      groups.push({
        key: 'menu.Player',
        routes: [{ key: 'menu.PlayerBrowser', route: ERoutes.PlayerBrowser }],
      });
    }
    // if (perms.reportsGameBrowser) {
    //   groups.push({
    //     key: 'menu.Games',
    //     disabled: true,
    //     routes: [{ key: 'menu.GameBrowser', route: ERoutes.GameBrowser, disabled: true }],
    //   });
    // }

    if (groups.length) {
      data.push({
        key: 'menu.Reports',
        icon: <AssignmentIcon fontSize="small" />,
        routes: [],
        groups,
      });
    }
  }

  if (perms.team) {
    data.push({
      key: 'menu.Management',
      icon: <ManageAccountsIcon fontSize="small" />,
      routes: [],
      groups: [
        {
          key: 'menu.Team',
          routes: [{ key: 'menu.Members', route: ERoutes.ManagementTeamMembers }],
        },
      ],
    });
  }

  // data.push(
  //   {
  //     key: 'menu.Monitoring',
  //     icon: <MonitorHeartIcon fontSize="small" />,
  //     disabled: true,
  //     routes: [
  //       { key: 'menu.Games', route: ERoutes.MonitoringGames, disabled: true },
  //       { key: 'menu.Players', route: ERoutes.MonitoringPlayers, disabled: true },
  //     ],
  //     groups: [],
  //   },
  // );

  return data;
};
